import { PrivacyPolicy } from './PrivacyPolicy';
import { Modal } from '@clevergy/ui/components/Modal';
import { useLegalDocuments } from 'hooks/useLegalDocuments';
import { useState } from 'react';

export const LegalFooter = () => {
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);

  const { isPending, legalDocuments } = useLegalDocuments();

  if (isPending) {
    return <p>...</p>;
  }

  if (legalDocuments.privacyPolicy || legalDocuments.termsOfService) {
    return (
      <>
        <p className="text-clevergy-subtext px-8 text-center text-xs">
          Al continuar, estás aceptando{' '}
          {legalDocuments.privacyPolicy && (
            <>
              nuestra{' '}
              <button
                type="button"
                className="text-clevergy-primary"
                onClick={() => legalDocuments.privacyPolicy?.open()}
              >
                Política de privacidad
              </button>{' '}
            </>
          )}
          {legalDocuments.termsOfService && (
            <>
              y{' '}
              <button
                type="button"
                className="text-clevergy-primary"
                onClick={() => legalDocuments.termsOfService?.open()}
              >
                Términos y condiciones de uso
              </button>
            </>
          )}
          .
        </p>
      </>
    );
  }

  return (
    <>
      <p className="text-clevergy-subtext px-8 text-center text-xs">
        Al continuar, estás aceptando nuestra{' '}
        <button
          type="button"
          className="text-clevergy-primary"
          onClick={() => setShowPrivacyPolicyModal(true)}
        >
          Política de privacidad
        </button>
        .
      </p>
      <Modal
        isOpen={showPrivacyPolicyModal}
        onClose={() => setShowPrivacyPolicyModal(false)}
      >
        <PrivacyPolicy />
      </Modal>
    </>
  );
};
