/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { UserIntegration } from './UserIntegration';
import {
  UserIntegrationFromJSON,
  UserIntegrationFromJSONTyped,
  UserIntegrationToJSON,
  UserIntegrationToJSONTyped,
} from './UserIntegration';

/**
 *
 * @export
 * @interface UserIntegrationsResponse
 */
export interface UserIntegrationsResponse {
  /**
   *
   * @type {UserIntegration}
   * @memberof UserIntegrationsResponse
   */
  datadis?: UserIntegration;
}

/**
 * Check if a given object implements the UserIntegrationsResponse interface.
 */
export function instanceOfUserIntegrationsResponse(
  value: object,
): value is UserIntegrationsResponse {
  return true;
}

export function UserIntegrationsResponseFromJSON(
  json: any,
): UserIntegrationsResponse {
  return UserIntegrationsResponseFromJSONTyped(json, false);
}

export function UserIntegrationsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UserIntegrationsResponse {
  if (json == null) {
    return json;
  }
  return {
    datadis:
      json['datadis'] == null
        ? undefined
        : UserIntegrationFromJSON(json['datadis']),
  };
}

export function UserIntegrationsResponseToJSON(
  json: any,
): UserIntegrationsResponse {
  return UserIntegrationsResponseToJSONTyped(json, false);
}

export function UserIntegrationsResponseToJSONTyped(
  value?: UserIntegrationsResponse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    datadis: UserIntegrationToJSON(value['datadis']),
  };
}
