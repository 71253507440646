/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface Equipment
 */
export interface Equipment {
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  equipmentId: string;
  /**
   *
   * @type {string}
   * @memberof Equipment
   */
  integrationType: EquipmentIntegrationTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof Equipment
   */
  firstDateEnergy?: Date;
  /**
   *
   * @type {Date}
   * @memberof Equipment
   */
  lastDateEnergy?: Date;
  /**
   *
   * @type {Date}
   * @memberof Equipment
   */
  firstDatePower?: Date;
  /**
   *
   * @type {Date}
   * @memberof Equipment
   */
  lastDatePower?: Date;
}

/**
 * @export
 */
export const EquipmentIntegrationTypeEnum = {
  Solar: 'SOLAR',
  Distributor: 'DISTRIBUTOR',
  Hydro: 'HYDRO',
  SmartDevice: 'SMART_DEVICE',
  Erp: 'ERP',
  Datadis: 'DATADIS',
  Community: 'COMMUNITY',
  Storage: 'STORAGE',
} as const;
export type EquipmentIntegrationTypeEnum =
  (typeof EquipmentIntegrationTypeEnum)[keyof typeof EquipmentIntegrationTypeEnum];

/**
 * Check if a given object implements the Equipment interface.
 */
export function instanceOfEquipment(value: object): value is Equipment {
  if (!('equipmentId' in value) || value['equipmentId'] === undefined)
    return false;
  if (!('integrationType' in value) || value['integrationType'] === undefined)
    return false;
  return true;
}

export function EquipmentFromJSON(json: any): Equipment {
  return EquipmentFromJSONTyped(json, false);
}

export function EquipmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Equipment {
  if (json == null) {
    return json;
  }
  return {
    equipmentId: json['equipmentId'],
    integrationType: json['integrationType'],
    firstDateEnergy:
      json['firstDateEnergy'] == null
        ? undefined
        : new Date(json['firstDateEnergy']),
    lastDateEnergy:
      json['lastDateEnergy'] == null
        ? undefined
        : new Date(json['lastDateEnergy']),
    firstDatePower:
      json['firstDatePower'] == null
        ? undefined
        : new Date(json['firstDatePower']),
    lastDatePower:
      json['lastDatePower'] == null
        ? undefined
        : new Date(json['lastDatePower']),
  };
}

export function EquipmentToJSON(json: any): Equipment {
  return EquipmentToJSONTyped(json, false);
}

export function EquipmentToJSONTyped(
  value?: Equipment | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    equipmentId: value['equipmentId'],
    integrationType: value['integrationType'],
    firstDateEnergy:
      value['firstDateEnergy'] == null
        ? undefined
        : value['firstDateEnergy'].toISOString().substring(0, 10),
    lastDateEnergy:
      value['lastDateEnergy'] == null
        ? undefined
        : value['lastDateEnergy'].toISOString().substring(0, 10),
    firstDatePower:
      value['firstDatePower'] == null
        ? undefined
        : value['firstDatePower'].toISOString().substring(0, 10),
    lastDatePower:
      value['lastDatePower'] == null
        ? undefined
        : value['lastDatePower'].toISOString().substring(0, 10),
  };
}
