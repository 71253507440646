import { UserHouses } from './components/UserHouses';
import { UserSummary } from './components/UserSummary';
import { DateRangeType } from '@clevergy/shared/types';
import { DateRangeNavigator } from '@clevergy/ui/components/DateRangeNavigator/DateRangeNavigator';
import { GlobalViewHeader } from 'components/GlobalViewHeader';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useSearchParams } from 'react-router-dom';

export const HousesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageFromParams = searchParams.get('page');
  const dateRangeCurrentDateFromParams = searchParams.get(
    'dateRangeCurrentDate',
  );

  const pageNumber = pageFromParams ? parseInt(pageFromParams) : 1;

  const dateRangeCurrentDate = dateRangeCurrentDateFromParams
    ? new Date(dateRangeCurrentDateFromParams)
    : new Date();

  const handlePageChange = (page: number) => {
    setSearchParams({
      page: page.toString(),
      dateRangeCurrentDate: format(dateRangeCurrentDate, 'yyyy-MM-dd'),
    });
  };

  const handleDateRangeChange = (newDate: Date) => {
    setSearchParams({
      page: pageNumber.toString(),
      dateRangeCurrentDate: format(newDate, 'yyyy-MM-dd'),
    });
  };

  return (
    <Page>
      <Wrapper>
        <div className="mx-auto max-w-screen-lg p-safe">
          <GlobalViewHeader>
            <DateRangeNavigator
              locale={es}
              dateRangeType={DateRangeType.Month}
              dateRangeCurrentDate={dateRangeCurrentDate}
              onChange={handleDateRangeChange}
              minStartDate={new Date('2022-01-01')}
              maxEndDate={new Date()}
            />
          </GlobalViewHeader>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4 bg-clevergy-moduleContainerBackground rounded-moduleContainer p-moduleContainerPadding shadow-moduleContainer">
              <UserHouses
                page={pageNumber}
                onPageChange={handlePageChange}
                dateRangeCurrentDate={dateRangeCurrentDate}
              />
            </div>
            <div className="flex flex-col w-full md:flex-row md:gap-4 md:justify-evenly">
              <UserSummary dateRangeCurrentDate={dateRangeCurrentDate} />
            </div>
          </div>
        </div>
      </Wrapper>
    </Page>
  );
};
