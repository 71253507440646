import { UserHousesList } from './UserHousesList';
import { UserHousesTotals } from './UserHousesTotals';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { Pagination } from '@clevergy/ui/components/Pagination';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useTranslation } from 'react-i18next';

export type UserHousesProps = {
  page?: number;
  onPageChange: (page: number) => void;
  dateRangeCurrentDate: Date;
};

export const UserHouses = (props: UserHousesProps) => {
  const { page = 1, onPageChange, dateRangeCurrentDate } = props;
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { t } = useTranslation();

  const userHousesQuery = useQuery({
    queryKey: [QueryKeys.GET_USER_HOUSES, { userId: authedUser?.uid, page }],
    queryFn: authedUser?.uid
      ? () =>
          api.users.getUserHouses({
            userId: authedUser?.uid,
            page,
            size: 10,
          })
      : skipToken,
    placeholderData: (prev) => prev,
  });

  if (userHousesQuery.isPending) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="semibold pb-2 text-xl font-semibold">
        {t('houses.totalHouses', {
          count: userHousesQuery.data?.totalElements,
        })}
      </div>
      <UserHousesTotals dateRangeCurrentDate={dateRangeCurrentDate} />
      <UserHousesList
        houses={userHousesQuery.data?.elements || []}
        dateRangeCurrentDate={dateRangeCurrentDate}
      />
      <Pagination
        page={page}
        totalPages={userHousesQuery.data?.totalPages || 0}
        onPageChange={onPageChange}
      />
    </div>
  );
};
