import {
  FindEnergyByHouseIdGranularityEnum,
  UserHouse,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { formatNumber } from '@clevergy/utils/format/formatNumber';
import { useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { endOfMonth, startOfMonth } from 'date-fns';
import { FC } from 'react';
import { Link } from 'react-router-dom';

type UserHousesListItemProps = {
  house: UserHouse;
  dateRangeCurrentDate?: Date;
};

export const UserHousesListItem: FC<UserHousesListItemProps> = (props) => {
  const { house, dateRangeCurrentDate } = props;
  const { api } = useApiContext();

  const startDate = startOfMonth(dateRangeCurrentDate || new Date());
  const endDate = endOfMonth(dateRangeCurrentDate || new Date());

  const energyByHouseIdQuery = useQuery({
    queryKey: [
      QueryKeys.FIND_HOUSE_ENERGY_BY_HOUSE_ID,
      {
        houseId: house.details.houseId,
        startDate,
        endDate,
        granularity: FindEnergyByHouseIdGranularityEnum.Daily,
      },
    ],
    queryFn: () =>
      api.energy.findEnergyByHouseId({
        houseId: house.details.houseId,
        startDate,
        endDate,
        granularity: FindEnergyByHouseIdGranularityEnum.Daily,
      }),
  });

  const totalConsumption = energyByHouseIdQuery.data?.reduce(
    (acc, curr) => acc + (curr.house?.consumption || 0),
    0,
  );

  const totalProduction = energyByHouseIdQuery.data?.reduce(
    (acc, curr) => acc + (curr.solar?.production || 0),
    0,
  );

  return (
    <Link
      to={`/houses/${house.details.houseId}`}
      className="group/item flex gap-2 items-center rounded-xl p-4 bg-neutral-50 md:bg-transparent md:table-row md:p-0"
    >
      <div className="flex flex-col gap-2 justify-between w-full md:contents">
        <div className="md:contents">
          <div className="font-semibold md:table-cell md:font-normal md:p-2 md:underline md:text-clevergy-primary group-odd/item:bg-neutral-50 rounded-l-lg">
            {house.details.address || 'Sin dirección'}
          </div>
          <div className="md:table-cell group-odd/item:bg-neutral-50 text-sm md:text-base">
            {house.details.cups}
          </div>
        </div>
        <div className="flex gap-2 font-semibold text-sm md:contents md:text-base">
          <div className="md:table-cell group-odd/item:bg-neutral-50 md:text-center">
            {energyByHouseIdQuery.isPending && (
              <LoadingIndicator variant="spinner" centered />
            )}
            {totalConsumption !== undefined && totalConsumption > 0 && (
              <>
                <div className="text-clevergy-subtext md:hidden">Consumo</div>
                {
                  formatNumber({
                    value: totalConsumption,
                    precision: 2,
                    language: 'es-ES',
                  }).formattedValue
                }{' '}
                kWh
              </>
            )}
          </div>
          <div className="md:table-cell group-odd/item:bg-neutral-50 rounded-r-lg md:text-center">
            {energyByHouseIdQuery.isPending && (
              <LoadingIndicator variant="spinner" centered />
            )}
            {totalProduction !== undefined && totalProduction > 0 && (
              <>
                <div className="text-clevergy-subtext md:hidden">
                  Producción
                </div>
                {
                  formatNumber({
                    value: totalProduction,
                    precision: 2,
                    language: 'es-ES',
                  }).formattedValue
                }{' '}
                kWh
              </>
            )}
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <FontIcon name="arrow_forward_ios" />
      </div>
    </Link>
  );
};
