import { Heading } from '@clevergy/ui/components/Heading';
import { TenantLogo } from 'components/TenantLogo';
import { FC, useRef } from 'react';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export type TenantPageIntroWithLogoProps = {
  title: string;
  subtitle?: string;
};

export const TenantPageIntroWithLogo: FC<TenantPageIntroWithLogoProps> = ({
  title,
  subtitle,
}) => {
  const logoClickCount = useRef(0);
  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

  const resetMultiTenant = useAppConfigurationStore((state) => () => {
    state.setTenantId(undefined);
  });

  const handleLogoClick = () => {
    logoClickCount.current += 1;
    if (logoClickCount.current >= 10) {
      logoClickCount.current = 0;
      resetMultiTenant();
    } else {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        logoClickCount.current = 0;
      }, 5000);
    }
  };

  return (
    <div className="flex flex-col items-center px-4 text-center">
      <div onClick={handleLogoClick} aria-hidden>
        <TenantLogo type="default" width={256} height={128} />
      </div>

      <div className="flex flex-col items-center">
        <Heading as="h2" colorScheme="neutral">
          {title}
        </Heading>
        {subtitle && <p className="text-clevergy-subtext">{subtitle}</p>}
      </div>
    </div>
  );
};
