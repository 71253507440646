import { useSelectedHouse } from './useSelectedHouse';
import { UserIntegrationStatusEnum } from '@clevergy/api-client';
import { useUserHouses } from 'context/UserHousesContext';
import { useUserIntegrationsContext } from 'context/UserIntegrationsContext';

export const useSmartMeterIntegration = () => {
  const { userIntegrations } = useUserIntegrationsContext();
  const { selectedHouse } = useSelectedHouse();
  const { houses } = useUserHouses();

  const hasNotEnergyDataOrNotHouses =
    houses?.length === 0 ||
    (typeof selectedHouse !== 'undefined' &&
      !selectedHouse?.houseMetadata.lastDateEnergy);

  // Suggest integration if datadis integration is available and not connected (none)
  const shouldSuggestSmartMeterIntegration =
    Boolean(userIntegrations?.datadis) &&
    userIntegrations?.datadis?.status === UserIntegrationStatusEnum.None &&
    hasNotEnergyDataOrNotHouses;

  // We assume that the integration is in progress if the integration is connected and the last date of energy is not set
  const isSmartMeterIntegrationInProgress =
    userIntegrations?.datadis?.status === UserIntegrationStatusEnum.Connected &&
    hasNotEnergyDataOrNotHouses;

  // Show draft modules if datadis integration available
  const shouldShowSmartMeterDraftModules =
    shouldSuggestSmartMeterIntegration || isSmartMeterIntegrationInProgress;

  return {
    shouldSuggestSmartMeterIntegration,
    shouldShowSmartMeterDraftModules,
    isSmartMeterIntegrationInProgress,
  };
};
