import { splitDisplayName } from '@clevergy/utils/format/splitDisplayName';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';

export const DataConsentPage = () => {
  const { token, authedUser } = useAuthContext();
  const { legalName, dpoEmail } = useTenantSettings();
  const { firstName, lastName } = splitDisplayName(authedUser?.displayName);

  if (!token) {
    return null;
  }

  return (
    <>
      <clevergy-data-consent
        data-token={token}
        data-consent-name={legalName}
        data-opt-out-email={dpoEmail}
        data-user-firstname={firstName}
        data-user-lastname={lastName}
        data-companies-enabled={'true'}
        data-delegate-url-opening={'true'}
      />
    </>
  );
};
