import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { ProcessIconSuccess } from '@clevergy/ui/components/ProcessIconSuccess';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useExternalOauthProxyStore } from 'stores/externalOauthProxyStore';

export const ExternalOauthHuaweiCallbackPage = () => {
  const isRedirecting = useRef(false);
  const { jwt, callbackUrl, houseId, reset } = useExternalOauthProxyStore();

  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isRedirecting.current) {
      return;
    }

    if (!callbackUrl) {
      setIsSuccess(true);
      reset();
      return;
    }

    isRedirecting.current = true;
    window.location.replace(callbackUrl);
  }, [jwt, callbackUrl, houseId, reset]);

  if (isSuccess) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <ProcessIconSuccess />
      </div>
    );
  }

  return <LoadingIndicator fullScreen="transparent" />;
};
