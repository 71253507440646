import { IssuesList } from './components/IssuesList';
import { LastTicket } from './components/LastTicket';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SupportPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('support.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <PullToRefresh />
      <Wrapper>
        <>
          <p className="mb-4">{t('support.description')}</p>
          <LastTicket />
          <IssuesList />
        </>
      </Wrapper>
    </Page>
  );
};
