import { AMPLITUDE_API_KEY } from '../constants/amplitude';
import * as amplitude from '@amplitude/analytics-browser';
import { Types } from '@amplitude/analytics-browser';
import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

declare global {
  interface Window {
    gtag?: (...args: unknown[]) => void;
  }
}

const EXCLUDED_DOMAINS = ['localhost', 'developer.clever.gy'];

function isExcludedFromTracking() {
  return (
    import.meta.env.DEV || EXCLUDED_DOMAINS.includes(window.location.hostname)
  );
}

export type TrackedEvent = Types.BaseEvent;
export type UserIdentity = {
  userId?: string;
  tenantId?: string | null;
  hasAnyHouseSolar?: boolean;
  isClevergyMember?: boolean;
  appVersion?: string;
  appBundleVersion?: string;
};

// Initialize Amplitude
amplitude.init(AMPLITUDE_API_KEY, {
  serverZone: 'EU',
  autocapture: {
    attribution: true,
    pageViews: true,
    sessions: true,
    formInteractions: true,
    fileDownloads: true,
    elementInteractions: true,
  },
  logLevel: amplitude.Types.LogLevel.Warn,
});

export type AnalyticsContextValue = {
  identify: (identity: UserIdentity) => void;
  track: (...args: Parameters<typeof amplitude.track>) => void;
  resetAnalytics: typeof amplitude.reset;
};

export const AnalyticsContext = createContext<AnalyticsContextValue | null>(
  null,
);

export const AnalyticsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const value: AnalyticsContextValue = useMemo(
    () => ({
      // Identify user with it userId and tenantId
      identify: (identity: UserIdentity) => {
        if (isExcludedFromTracking()) {
          console.log('Track identify (excluded):', identity);
          return;
        }
        if (identity.userId) {
          amplitude.setUserId(identity.userId);
          window?.gtag?.('set', 'user_properties', {
            user_id: identity.userId,
          });
        }

        const identify = new amplitude.Identify();

        if (identity.tenantId) {
          identify.set('tenant_id', identity.tenantId);
          window?.gtag?.('set', 'user_properties', {
            tenant_id: identity.tenantId,
          });
        }
        if (typeof identity.isClevergyMember !== 'undefined') {
          identify.set('clevergy_member', identity.isClevergyMember);
          window?.gtag?.('set', 'user_properties', {
            clevergy_member: identity.isClevergyMember,
          });
        }
        if (typeof identity.hasAnyHouseSolar !== 'undefined') {
          identify.set('solar', identity.hasAnyHouseSolar);
          window?.gtag?.('set', 'user_properties', {
            solar: identity.hasAnyHouseSolar,
          });
        }
        if (identity.appVersion) {
          identify.set('app_version', identity.appVersion);
          window?.gtag?.('set', 'user_properties', {
            app_version: identity.appVersion,
          });
        }
        if (identity.appBundleVersion) {
          identify.set('app_bundle_version', identity.appBundleVersion);
          window?.gtag?.('set', 'user_properties', {
            app_bundle_version: identity.appBundleVersion,
          });
        }

        amplitude.identify(identify);
      },

      // Track an event
      track: (...args) => {
        const { event_type, event_properties } = args[0] as TrackedEvent;

        if (isExcludedFromTracking()) {
          console.log('Track event (excluded):', ...args);
          return;
        }

        // Track event to Amplitude
        amplitude.track(...args);

        // Track event to Google
        window?.gtag?.('event', event_type, event_properties);
      },

      // Reset analytics session and device id
      resetAnalytics: amplitude.reset,
    }),
    [],
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(
      'useAnalyticsContext must be used within AnalyticsProvider',
    );
  }

  return context;
};
