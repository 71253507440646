import clsx from 'clsx';
import { ReactNode, forwardRef, useState } from 'react';

export type InputCheckToggleSwitchProps = {
  id: string;
  name: string;
  label: string | ReactNode;
  hiddenLabel?: boolean;
  value?: string;
  error?: string;
  isChecked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const InputCheckToggleSwitch = forwardRef<
  HTMLInputElement,
  InputCheckToggleSwitchProps
>((props, ref) => {
  const {
    id,
    name,
    label,
    hiddenLabel,
    error,
    value,
    disabled,
    isChecked = false,
    onChange,
  } = props;
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div data-checked={isChecked} data-focused={isFocused}>
      <div
        className={clsx('inline-flex cursor-pointer gap-2', {
          'opacity-80': disabled,
        })}
      >
        <span
          className={clsx('relative shrink-0 rounded-full h-6 w-10', {
            'bg-clevergy-unselected': !isChecked,
            'bg-clevergy-primary': isChecked,
          })}
        >
          <span
            className={clsx(
              'absolute h-6 w-6 translate-x-0 transform rounded-full border bg-white transition-all',
              {
                'border-clevergy-unselected translate-x-0': !isChecked,
                'bg-clevergy-primary translate-x-4': isChecked,
              },
            )}
          ></span>
          <input
            ref={ref}
            id={id}
            name={name}
            type="checkbox"
            aria-invalid={Boolean(error)}
            checked={isChecked}
            value={value}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={onChange}
            disabled={disabled}
            className={clsx('absolute inset-0 z-0 h-full w-full opacity-0', {
              'cursor-pointer': !disabled,
              'cursor-not-allowed': disabled,
            })}
          />
        </span>
        <div className={clsx({ 'sr-only': hiddenLabel })}>
          {typeof label === 'string' ? (
            <label
              htmlFor={id}
              className={clsx('font-semibold', {
                'text-clevergy-unselected': disabled,
              })}
            >
              {label}
            </label>
          ) : (
            <>{label}</>
          )}
        </div>
      </div>
      {error && <p className="text-red-800 text-sm">{error}</p>}
    </div>
  );
});

InputCheckToggleSwitch.displayName = 'InputCheckToggleSwitch';
