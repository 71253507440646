import { PreferencesNotificationToggle } from './components/PreferencesNotificationToggle';
import { GetPreferencesNotificationsTypeEnum } from '@clevergy/api-client';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useNotificationsContext } from 'context/NotificationsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NotificationsPage: FC = () => {
  const { t } = useTranslation();

  const { permissionsStatus, requestNotificationsPermission } =
    useNotificationsContext();

  const { hasSolarEquipment, hasEnergyCommunityEquipment } = useSelectedHouse();

  const canRequestNotificationsPermission =
    permissionsStatus && permissionsStatus !== 'unavailable';

  const displayExportToggle = hasSolarEquipment || hasEnergyCommunityEquipment;

  return (
    <Page>
      <PageHeader
        backButton
        title={t('notifications.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        <ModuleCard>
          <ModuleCard.Header title={t('notifications.title')} />
          <ModuleCard.Content>
            <p className="mb-4">
              {t('notifications.subtitle.part1')}{' '}
              {canRequestNotificationsPermission && (
                <button
                  onClick={() => requestNotificationsPermission()}
                  className="text-clevergy-primary underline"
                >
                  {t('notifications.subtitle.phoneSettingsLink')}
                </button>
              )}
              {t('notifications.subtitle.part2')}
            </p>

            <div className="flex flex-col gap-4">
              <PreferencesNotificationToggle
                type={GetPreferencesNotificationsTypeEnum.ConsumptionReport}
                title={
                  t('notifications.types.consumptionReport.title') as string
                }
                description={
                  t(
                    'notifications.types.consumptionReport.description',
                  ) as string
                }
              />

              {displayExportToggle && (
                <PreferencesNotificationToggle
                  type={GetPreferencesNotificationsTypeEnum.Export}
                  title={t('notifications.types.export.title') as string}
                  description={
                    t('notifications.types.export.description') as string
                  }
                />
              )}
            </div>
          </ModuleCard.Content>
        </ModuleCard>
      </Wrapper>
    </Page>
  );
};
