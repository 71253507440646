import { InvoicesFilter } from './components/InvoicesFilter';
import { InvoicesList } from './components/InvoicesList';
import { InvoicesNoData } from './components/InvoicesNoData';
import { HouseInvoiceSummaryResponse } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { skipToken, useQuery } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const TWO_HOURS = 2 * 60 * 60 * 1000;

export const InvoicesPage: FC = () => {
  const { t } = useTranslation();

  const { api } = useApiContext();
  const { selectedHouse } = useSelectedHouse();

  const [currentYear, setCurrentYear] = useState<number | null>(null);

  const invoicesQuery = useQuery({
    queryKey: [
      QueryKeys.GET_INVOICES_PAGE_FOR_HOUSE,
      {
        houseId: selectedHouse?.details.houseId,
      },
    ],
    queryFn: selectedHouse?.details.houseId
      ? () =>
          api.invoices.getHouseInvoicesByYear({
            houseId: selectedHouse.details.houseId,
          })
      : skipToken,
    staleTime: TWO_HOURS,
  });

  const availableYears = useMemo(() => {
    if (!invoicesQuery.data) {
      return [];
    }

    const result = invoicesQuery.data
      .filter((item) => item.startDate)
      .map((item) => item.startDate)
      .reduce((acc: number[], curr) => {
        if (curr) {
          const year = curr.getFullYear();
          if (!acc.includes(year)) {
            return [...acc, year];
          }
        }
        return acc;
      }, []) as number[];

    return result.sort((a, b) => a - b);
  }, [invoicesQuery.data]);

  const filteredInvoices = useMemo(() => {
    if (!invoicesQuery.data) {
      return [];
    }

    return invoicesQuery.data
      .filter(
        (invoice) =>
          invoice.startDate &&
          new Date(invoice.startDate).getFullYear() === currentYear,
      )
      .sort(
        (
          firstInvoice: HouseInvoiceSummaryResponse,
          secondInvoice: HouseInvoiceSummaryResponse,
        ) => {
          if (!firstInvoice.startDate || !secondInvoice.startDate) {
            return -1;
          }

          const result =
            new Date(secondInvoice.startDate).getTime() -
            new Date(firstInvoice.startDate).getTime();

          return result;
        },
      );
  }, [invoicesQuery.data, currentYear]);

  // Set the current year to the last available year if it's not set
  useEffect(
    () =>
      setCurrentYear((currentYear) =>
        availableYears.length > 0 && !currentYear
          ? availableYears[availableYears.length - 1]
          : currentYear,
      ),
    [availableYears, currentYear],
  );

  const displayNoData =
    !invoicesQuery.isPending &&
    (!filteredInvoices || filteredInvoices.length === 0);

  const displayInvoiceList =
    !invoicesQuery.isPending && filteredInvoices.length > 0;

  return (
    <Page>
      <PageHeader
        backButton
        title={t('invoices.title') as string}
        hideTitleAndBackButtonForNonMobile
      />
      <Wrapper>
        <ModuleCard>
          <ModuleCard.Content>
            {availableYears.length > 0 && currentYear && (
              <InvoicesFilter
                availableYears={availableYears}
                currentYear={currentYear}
                setCurrentYear={setCurrentYear}
              />
            )}

            {displayNoData && <InvoicesNoData />}

            {displayInvoiceList && selectedHouse?.details.houseId && (
              <InvoicesList invoices={filteredInvoices} />
            )}
          </ModuleCard.Content>
        </ModuleCard>
      </Wrapper>
    </Page>
  );
};
