import { CommentRequestAuthorEnum } from '@clevergy/api-client';
import { Button } from '@clevergy/ui/components/Button';
import { InputTextArea } from '@clevergy/ui/components/InputTextArea';
import { useMutation } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

type SupportTicketRespondFormSchema = {
  message: string;
};

export const SupportTicketRespondPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApiContext();
  const { ticketId } = useParams();
  const { authedUser } = useAuthContext();

  const sendCommentMutation = useMutation({
    mutationFn: (params: SupportTicketRespondFormSchema) => {
      if (!ticketId || !authedUser || !authedUser.tenantId) {
        throw new Error('User not authenticated');
      }

      return api.tickets.addCommentToTicket({
        ticketId,
        userId: authedUser.uid,
        commentRequest: {
          tenantId: authedUser.tenantId,
          message: params.message,
          author: CommentRequestAuthorEnum.User,
        },
      });
    },
    onSuccess: () => {
      onFinish();
    },
  });

  const { register, handleSubmit, formState, clearErrors } =
    useForm<SupportTicketRespondFormSchema>();

  const handleSubmitRespondTicketForm = (
    data: SupportTicketRespondFormSchema,
  ) => sendCommentMutation.mutate(data);

  const onFinish = () => {
    navigate('/support/tickets/success');
  };

  if (!ticketId || !authedUser) {
    return (
      <Page navbarLayoutContentMobileBackground="white">
        <Wrapper>
          <div className="flex w-full justify-center items-center h-full">
            <p>{t('support.respondTicket.noTicket')}</p>
          </div>
        </Wrapper>
      </Page>
    );
  }

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath={`/support/tickets/${ticketId}`}
        title={t('support.respondTicket.title') as string}
      />
      <Wrapper>
        <form
          onSubmit={handleSubmit(handleSubmitRespondTicketForm)}
          className="flex w-full flex-col gap-4 justify-center"
        >
          <InputTextArea
            id="message"
            rows={12}
            autoComplete="off"
            placeholder={t('support.respondTicket.form.message.placeholder')}
            error={formState.errors.message?.message}
            {...register('message', {
              required: t(
                'support.respondTicket.form.message.required',
              ) as string,
            })}
          />

          <Button
            fullWidth
            type="submit"
            variant="filled"
            disabled={
              formState.isSubmitting ||
              !formState.isValid ||
              sendCommentMutation.isPending
            }
            busy={sendCommentMutation.isPending}
            onClick={() => clearErrors()}
          >
            {t('support.respondTicket.form.actions.submit')}
          </Button>
        </form>
      </Wrapper>
    </Page>
  );
};
