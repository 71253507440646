import { HousePicker } from 'components/HousePicker';
import { MobileNavbarMenu } from 'components/MobileNavbarMenu';
import { NonMobileNavbarMenu } from 'components/NonMobileNavbarMenu';
import { TenantLogo } from 'components/TenantLogo';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';

export const NavbarLayout = () => {
  const location = useLocation();
  const scrollDivRef = useRef<HTMLDivElement>(null);

  // reset scroll position when location changes
  useEffect(() => {
    if (location && scrollDivRef.current) {
      scrollDivRef.current.scrollTop = 0;
    }
  }, [location]);

  return (
    <div
      id="navbar-layout"
      className="flex flex-col sm:flex-row h-full bg-[var(--navbar-layout-content-mobile-background)] sm:bg-transparent"
    >
      <div className="hidden w-[260px] shrink-0 overflow-auto bg-white sm:block xl:fixed xl:inset-y-0 xl:left-0 z-10">
        <div className="flex flex-col h-full w-full">
          <div className="px-4 pt-6 pb-8">
            <HousePicker />
          </div>
          <NonMobileNavbarMenu />
          <div className="grow"></div>
          <div className="px-4 py-4">
            <TenantLogo type="default" width={180} height={90} />
          </div>
        </div>
      </div>
      <div
        className="grow overflow-y-auto xl:overflow-visible relative"
        ref={scrollDivRef}
      >
        <div className="min-h-full sm:mx-auto sm:min-h-fit md:max-w-[--navbar-layout-content-md-max-width] lg:py-8">
          <Outlet />
        </div>
      </div>
      <MobileNavbarMenu />
    </div>
  );
};
