import { NumberWithDecimals } from '@clevergy/ui/components/NumberWithDecimals';
import { useTranslation } from 'react-i18next';

export type DoubleKpiProps = {
  kpiLeft: {
    title: string;
    value?: number;
    unit: string;
    precision?: number;
  };
  kpiRight: {
    title: string;
    value?: number;
    unit: string;
    precision?: number;
  };
};

export const DoubleKpi = (props: DoubleKpiProps) => {
  const { kpiLeft, kpiRight } = props;

  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="flex justify-between">
      <div className="w-1/2 pr-2">
        {kpiLeft.value && (
          <>
            <p className="text-sm font-semibold">{kpiLeft.title}</p>
            <p>
              <NumberWithDecimals
                value={kpiLeft.value}
                precision={kpiLeft.precision}
                unit={kpiLeft.unit}
                language={language}
                valueClassName="text-3xl font-semibold"
                decimalClassName="text-3xl font-semibold"
                unitClassName="text-base"
              />
            </p>
          </>
        )}
      </div>
      <div className="w-1/2 border-l border-clevergy-border pl-6">
        {kpiRight.value && (
          <>
            <p className="text-sm font-semibold">{kpiRight.title}</p>
            <p>
              <NumberWithDecimals
                value={kpiRight.value}
                precision={kpiRight.precision}
                unit={kpiRight.unit}
                language={language}
                valueClassName="text-3xl font-semibold"
                decimalClassName="text-3xl font-semibold"
                unitClassName="text-base"
              />
            </p>
          </>
        )}
      </div>
    </div>
  );
};
