import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useMemo } from 'react';
import { useHousesKioskStore } from 'stores/houseKioskStore';

export const Header: FC = () => {
  const { selectedHouse } = useSelectedHouse();
  const housesKioskSettings = useHousesKioskStore(
    (state) => state.housesKioskSettings,
  );

  const kioskSettings = useMemo(() => {
    if (!selectedHouse) {
      return undefined;
    }
    const kioskSettings = housesKioskSettings[selectedHouse?.details.houseId];
    return kioskSettings;
  }, [housesKioskSettings, selectedHouse]);

  return (
    <div className="flex flex-col text-center w-full mb-16">
      {kioskSettings?.title && (
        <div className="text-4xl font-semibold text-clevergy-primary">
          {kioskSettings?.title}
        </div>
      )}
      {kioskSettings?.subtitle && (
        <div className="text-lg font-semibold text-clevergy-subtext">
          {kioskSettings?.subtitle}
        </div>
      )}
    </div>
  );
};
