/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { CumulativeConsumption } from '../models/index';
import {
  CumulativeConsumptionFromJSON,
  CumulativeConsumptionToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetHouseCumulativeConsumptionRequest {
  houseId: string;
  date: Date;
}

/**
 *
 */
export class ConsumptionApi extends runtime.BaseAPI {
  /**
   * Returns the cumulative consumption for a house.
   */
  async getHouseCumulativeConsumptionRaw(
    requestParameters: GetHouseCumulativeConsumptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CumulativeConsumption>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getHouseCumulativeConsumption().',
      );
    }

    if (requestParameters['date'] == null) {
      throw new runtime.RequiredError(
        'date',
        'Required parameter "date" was null or undefined when calling getHouseCumulativeConsumption().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['date'] != null) {
      queryParameters['date'] = (
        requestParameters['date'] as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/cumulative-consumption`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CumulativeConsumptionFromJSON(jsonValue),
    );
  }

  /**
   * Returns the cumulative consumption for a house.
   */
  async getHouseCumulativeConsumption(
    requestParameters: GetHouseCumulativeConsumptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CumulativeConsumption> {
    const response = await this.getHouseCumulativeConsumptionRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
