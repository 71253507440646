import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const AlertsPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();

  return (
    <Page>
      <PageHeader
        backButton
        title={t('alerts.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {token && (
          <>
            {selectedHouse && (
              <clevergy-alerts-settings
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}
          </>
        )}
      </Wrapper>
    </Page>
  );
};
