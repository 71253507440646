import { FontIcon } from '@clevergy/ui/components/FontIcon';
import clsx from 'clsx';
import { FC, useMemo } from 'react';

const MAX_PAGES_TO_SHOW = 10;

export type PaginationProps = {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const Pagination: FC<PaginationProps> = (props) => {
  const { page, totalPages, onPageChange } = props;

  const handleGoPage = (targetPage: number) => {
    if (targetPage < 1) {
      onPageChange(1);
      return;
    }

    if (targetPage > totalPages) {
      onPageChange(totalPages);
      return;
    }

    onPageChange(targetPage);
  };

  const desktopVersionListOfPages = useMemo(() => {
    const listOfPages = [];

    for (
      let i = Math.max(1, page - MAX_PAGES_TO_SHOW / 2);
      i <= Math.min(totalPages, page + MAX_PAGES_TO_SHOW / 2);
      i++
    ) {
      listOfPages.push(i);
    }

    return listOfPages;
  }, [totalPages, page]);

  const isHiddingPagesAtTheStart = useMemo(() => {
    return desktopVersionListOfPages[0] > 1;
  }, [desktopVersionListOfPages]);

  const isHiddingPagesAtTheEnd = useMemo(() => {
    return (
      desktopVersionListOfPages[desktopVersionListOfPages.length - 1] <
      totalPages
    );
  }, [desktopVersionListOfPages, totalPages]);

  if (!totalPages || totalPages === 1) {
    return null;
  }

  return (
    <div className="flex items-center justify-between md:justify-center gap-8 select-none">
      <button
        onClick={() => handleGoPage(page - 1)}
        className={clsx(
          'rounded-lg border border-clevergy-border bg-white p-1',
          {
            'opacity-40': page === 1,
          },
        )}
        disabled={page === 1}
      >
        <FontIcon name="chevron_left" />
      </button>
      <div>
        {/* Mobile version */}
        <span className="block md:hidden">
          {page} de {totalPages}
        </span>
        {/* Desktop version */}
        <span className="hidden md:flex gap-4">
          {isHiddingPagesAtTheStart && (
            <>
              <PaginationButton
                page={1}
                currentPage={page}
                onClick={() => handleGoPage(1)}
              />
              <span className="text-clevergy-border">...</span>
            </>
          )}
          {desktopVersionListOfPages.map((item) => (
            <PaginationButton
              key={item}
              page={item}
              currentPage={page}
              onClick={() => handleGoPage(item)}
            />
          ))}
          {isHiddingPagesAtTheEnd && (
            <>
              <span className="text-clevergy-border">...</span>
              <PaginationButton
                page={totalPages}
                currentPage={page}
                onClick={() => handleGoPage(totalPages)}
              />
            </>
          )}
        </span>
      </div>
      <button
        onClick={() => handleGoPage(page + 1)}
        className={clsx(
          'rounded-lg border border-clevergy-border bg-white p-1',
          {
            'opacity-40': page === totalPages,
          },
        )}
        disabled={page === totalPages}
      >
        <FontIcon name="chevron_right" />
      </button>
    </div>
  );
};

type PaginationButtonProps = {
  page: number;
  currentPage: number;
  onClick: () => void;
};

const PaginationButton: FC<PaginationButtonProps> = (props) => {
  const { page, currentPage, onClick } = props;

  return (
    <button
      onClick={onClick}
      className={clsx({ 'opacity-40': page !== currentPage })}
    >
      {page}
    </button>
  );
};
