/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateHouse
 */
export interface CreateHouse {
  /**
   * CUPS of the house
   * @type {string}
   * @memberof CreateHouse
   */
  cups?: string;
  /**
   * address (horizontal address. street, number, etc)
   * @type {string}
   * @memberof CreateHouse
   */
  address?: string;
  /**
   * postal code
   * @type {string}
   * @memberof CreateHouse
   */
  postalCode?: string;
  /**
   * block number
   * @type {string}
   * @memberof CreateHouse
   */
  addressBlockNumber?: string;
  /**
   * floor
   * @type {string}
   * @memberof CreateHouse
   */
  addressFloor?: string;
  /**
   * stair
   * @type {string}
   * @memberof CreateHouse
   */
  addressStaircase?: string;
  /**
   * door
   * @type {string}
   * @memberof CreateHouse
   */
  addressDoor?: string;
  /**
   * address additional info
   * @type {string}
   * @memberof CreateHouse
   */
  addressAdditionalInfo?: string;
}

/**
 * Check if a given object implements the CreateHouse interface.
 */
export function instanceOfCreateHouse(value: object): value is CreateHouse {
  return true;
}

export function CreateHouseFromJSON(json: any): CreateHouse {
  return CreateHouseFromJSONTyped(json, false);
}

export function CreateHouseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateHouse {
  if (json == null) {
    return json;
  }
  return {
    cups: json['cups'] == null ? undefined : json['cups'],
    address: json['address'] == null ? undefined : json['address'],
    postalCode: json['postalCode'] == null ? undefined : json['postalCode'],
    addressBlockNumber:
      json['addressBlockNumber'] == null
        ? undefined
        : json['addressBlockNumber'],
    addressFloor:
      json['addressFloor'] == null ? undefined : json['addressFloor'],
    addressStaircase:
      json['addressStaircase'] == null ? undefined : json['addressStaircase'],
    addressDoor: json['addressDoor'] == null ? undefined : json['addressDoor'],
    addressAdditionalInfo:
      json['addressAdditionalInfo'] == null
        ? undefined
        : json['addressAdditionalInfo'],
  };
}

export function CreateHouseToJSON(json: any): CreateHouse {
  return CreateHouseToJSONTyped(json, false);
}

export function CreateHouseToJSONTyped(
  value?: CreateHouse | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    cups: value['cups'],
    address: value['address'],
    postalCode: value['postalCode'],
    addressBlockNumber: value['addressBlockNumber'],
    addressFloor: value['addressFloor'],
    addressStaircase: value['addressStaircase'],
    addressDoor: value['addressDoor'],
    addressAdditionalInfo: value['addressAdditionalInfo'],
  };
}
