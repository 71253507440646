import { BoardContent } from './BoardContent';
import clsx from 'clsx';
import { forwardRef } from 'react';

export type BoardProps = {
  isFullscreen: boolean;
};

export const Board = forwardRef<HTMLDivElement, BoardProps>((props, ref) => {
  const { isFullscreen } = props;
  return (
    <div
      ref={ref}
      className={clsx('flex flex-col items-center justify-center gap-4 p-4', {
        hidden: !isFullscreen,
      })}
      style={{
        background: 'var(--clevergy-app-background, #f5f5f5)',
      }}
    >
      <BoardContent />
    </div>
  );
});

Board.displayName = 'Board';
