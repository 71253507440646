import { SurplusRecommendations } from './components/SurplusRecommendations';
import { AppAlerts } from 'components/AppAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { SmartMeterBanner } from 'components/SmartMeterBanner';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';

export const OptimizePage: FC = () => {
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();
  const { hasRateRecommenderEnabled, tenantId } = useTenantSettings();

  return (
    <Page>
      <PageHeader withHousePicker />
      <PullToRefresh />
      <Wrapper>
        {!token && <TenantLoadingIndicator />}
        {token && (
          <>
            <AppAlerts />

            <SmartMeterBanner />

            {selectedHouse && (
              <clevergy-recommendations
                data-token={token}
                data-has-badge="true"
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-solar-recommender
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && <SurplusRecommendations />}

            <clevergy-tips-panel
              data-token={token}
              data-house-id={selectedHouse?.details.houseId}
            />

            {selectedHouse && hasRateRecommenderEnabled && (
              <clevergy-rate-recommender
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
                data-variant={
                  tenantId === 'smartconsum' ? 'lead-capture' : 'default'
                }
              />
            )}
          </>
        )}
      </Wrapper>
    </Page>
  );
};
