import { Board } from './components/Board';
import { BoardSettings } from './components/BoardSettings';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use';
import screenfull from 'screenfull';

export const KioskModePage = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const boardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useMount(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
      screenfull.on('error', () => {
        alert('Error al entrar en modo pantalla completa');
        setIsFullscreen(false);
      });
    }
  });

  const handleFullscreen = () => {
    if (boardRef.current) {
      screenfull.request(boardRef.current);
    }
  };

  if (!screenfull.isEnabled) {
    return <div>This feature is not available in this browser</div>;
  }

  return (
    <Page>
      <PageHeader
        backButton
        title={t('kioskMode.headerTitle') as string}
        hideTitleAndBackButtonForNonMobile
      />
      <Wrapper>
        <ModuleCard>
          <ModuleCard.Content>
            <BoardSettings onDone={handleFullscreen} />
          </ModuleCard.Content>
        </ModuleCard>
        <Board ref={boardRef} isFullscreen={isFullscreen} />
      </Wrapper>
    </Page>
  );
};
