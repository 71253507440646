import { AppTutorialRoute } from 'components/AppTutorialRoute';
import { GlobalSideBarMenuRoute } from 'components/GlobalSideBarMenuRoute';
import { HousePagesRedirect } from 'components/HousePagesRedirect';
import { MultiTenantRoute } from 'components/MultiTenantRoute';
import { OnlyPrivateRoute } from 'components/OnlyPrivateRoute';
import { OnlyPublicRoute } from 'components/OnlyPublicRoute';
import { UserSetupRedirectRoute } from 'components/UserSetupRedirectRoute';
import { AppLayout } from 'layouts/AppLayout';
import { HouseLayout } from 'layouts/HouseLayout';
import { IsolatedLayout } from 'layouts/IsolatedLayout';
import { NavbarLayout } from 'layouts/NavbarLayout';
import { AccountPage } from 'pages/account/AccountPage';
import { AlertsPage } from 'pages/alerts/AlertsPage';
import { AnalysisPage } from 'pages/analysis/AnalysisPage';
import { ChangePasswordPage } from 'pages/auth/ChangePasswordPage';
import { ForgotPasswordPage } from 'pages/auth/ForgotPasswordPage';
import { LoginPage } from 'pages/auth/LoginPage';
import { LoginWithTokenPage } from 'pages/auth/LoginWithTokenPage';
import { LogoutPage } from 'pages/auth/LogoutPage';
import { SignupPage } from 'pages/auth/SignupPage';
import { ContactPage } from 'pages/contact/ContactPage';
import { ContractPage } from 'pages/contract/ContractPage';
import { CreateHouseContractPage } from 'pages/contract/CreateHouseContractPage';
import { NotFoundPage } from 'pages/errors/NotFoundPage';
import { ExternalOauthHuaweiCallbackPage } from 'pages/external-oauth/ExternalOauthHuaweiCallbackPage';
import { ExternalOauthHuaweiPage } from 'pages/external-oauth/ExternalOauthHuaweiPage';
import { HomePage } from 'pages/home/HomePage';
import { HouseSettingsPage } from 'pages/house-settings/HouseSettingsPage';
import { HousesPage } from 'pages/houses/HousesPage';
import { IntegrationsPage } from 'pages/integrations/IntegrationsPage';
import { DataConsentPage } from 'pages/integrations/datadis/DataConsentPage';
import { DatadisIntegrationPage } from 'pages/integrations/datadis/DatadisIntegrationPage';
import { HuaweiExplanationPage } from 'pages/integrations/huawei/HuaweiExplanationPage';
import { ShellyExplanationPage } from 'pages/integrations/shelly/ShellyExplanationPage';
import { InvoicesPage } from 'pages/invoices/InvoicesPage';
import { KioskModePage } from 'pages/kiosk-mode/KioskModePage';
import { PrivacypolicyPage } from 'pages/legal/PrivacypolicyPage';
import { MorePage } from 'pages/more/MorePage';
import { NotificationsPage } from 'pages/notifications/NotificationsPage';
import { OnBoardingNotificationsPage } from 'pages/notifications/OnBoardingNotificationsPage';
import { OauthHuaweiPage } from 'pages/oauth/OauthHuaweiPage';
import { OptimizePage } from 'pages/optimize/OptimizePage';
import { SharedAccessPage } from 'pages/shared-access/SharedAccessPage';
import { SmartDevicePage } from 'pages/smart-devices/SmartDevicePage';
import { SmartDevicesPage } from 'pages/smart-devices/SmartDevicesPage';
import { SupportPage } from 'pages/support/SupportPage';
import { SupportTicketCreatePage } from 'pages/support/SupportTicketCreatePage';
import { SupportTicketPage } from 'pages/support/SupportTicketPage';
import { SupportTicketRespondPage } from 'pages/support/SupportTicketRespondPage';
import { SupportTicketSuccessPage } from 'pages/support/SupportTicketSuccessPage';
import { SupportTicketsPage } from 'pages/support/SupportTicketsPage';
import { Route, Routes } from 'react-router-dom';

export const AppRoutes = () => {
  return (
    <>
      <Routes>
        {/* App layout */}
        <Route element={<AppLayout />}>
          {/* Multi tenant wrapper */}
          <Route element={<MultiTenantRoute />}>
            {/* App tutorial modal */}
            <Route element={<AppTutorialRoute />}>
              {/* Pages only reachable when user is authenticated */}
              <Route element={<OnlyPrivateRoute />}>
                {/* Private pages with User setup redirect */}
                <Route element={<UserSetupRedirectRoute />}>
                  <Route element={<GlobalSideBarMenuRoute />}>
                    {/* Exclude houses from navbar layout */}
                    <Route path="/houses" element={<HousesPage />} />

                    {/* Private pages with Navbar layout */}
                    <Route element={<NavbarLayout />}>
                      {/* Common routes */}
                      <Route path="/more" element={<MorePage />} />
                      <Route path="/account" element={<AccountPage />} />
                      <Route path="/contact" element={<ContactPage />} />

                      {/* For no houses user */}
                      <Route element={<HousePagesRedirect />}>
                        <Route index element={<HomePage />} />
                        <Route path="/analysis" element={<AnalysisPage />} />
                        <Route path="/optimize" element={<OptimizePage />} />
                        <Route path="/alerts" element={<AlertsPage />} />
                        <Route path="/invoices" element={<InvoicesPage />} />
                        <Route path="/contract" element={<ContractPage />} />
                        <Route
                          path="/settings"
                          element={<HouseSettingsPage />}
                        />
                        <Route
                          path="/smart-devices"
                          element={<SmartDevicesPage />}
                        />
                      </Route>

                      {/* For user with houses */}

                      <Route path="/houses/:houseId" element={<HouseLayout />}>
                        <Route index element={<HomePage />} />
                        <Route path="analysis" element={<AnalysisPage />} />
                        <Route path="optimize" element={<OptimizePage />} />
                        <Route path="invoices" element={<InvoicesPage />} />
                        <Route
                          path="settings"
                          element={<HouseSettingsPage />}
                        />
                        <Route path="contract" element={<ContractPage />} />
                        <Route path="alerts" element={<AlertsPage />} />
                        {/* Smart devices */}
                        <Route path="smart-devices">
                          <Route index element={<SmartDevicesPage />} />
                          <Route
                            path=":deviceId/:channel"
                            element={<SmartDevicePage />}
                          />
                        </Route>

                        <Route
                          path="shared-access"
                          element={<SharedAccessPage />}
                        />
                        <Route path="kiosk-mode" element={<KioskModePage />} />
                      </Route>

                      {/* Integrations */}
                      <Route path="integrations">
                        <Route index element={<IntegrationsPage />} />
                        <Route
                          path="shelly"
                          element={<ShellyExplanationPage />}
                        />
                        <Route
                          path="huawei"
                          element={<HuaweiExplanationPage />}
                        />
                        <Route
                          path="datadis"
                          element={<DatadisIntegrationPage />}
                        />
                        <Route
                          path="datadis/connect"
                          element={<DatadisIntegrationPage />}
                        />
                      </Route>

                      {/* Support */}
                      <Route path="/support">
                        <Route index element={<SupportPage />} />
                        <Route path="tickets">
                          <Route index element={<SupportTicketsPage />} />
                          <Route
                            path="create"
                            element={<SupportTicketCreatePage />}
                          />
                          <Route path=":ticketId">
                            <Route index element={<SupportTicketPage />} />
                            <Route
                              path="submit-respond"
                              element={<SupportTicketRespondPage />}
                            />
                          </Route>
                          <Route
                            path="success"
                            element={<SupportTicketSuccessPage />}
                          />
                        </Route>
                      </Route>

                      {/* Notifications */}
                      <Route
                        path="/notifications"
                        element={<NotificationsPage />}
                      />

                      {/* Oauth callbacks */}
                      <Route
                        path="/oauth/huawei"
                        element={<OauthHuaweiPage />}
                      />
                    </Route>

                    {/* Private pages with isolated layout */}
                    <Route element={<IsolatedLayout />}>
                      <Route
                        element={<OnBoardingNotificationsPage />}
                        path={'/onboarding-notifications'}
                      />
                    </Route>
                  </Route>
                </Route>

                {/* Private pages with isolated layout outside of User setup redirect */}
                <Route element={<IsolatedLayout />}>
                  <Route
                    element={<ChangePasswordPage />}
                    path={'/change-password'}
                  />
                  <Route
                    path="/create-house-contract"
                    element={<CreateHouseContractPage />}
                  />
                  <Route path="/data-consent" element={<DataConsentPage />} />
                </Route>
              </Route>

              {/* Pages only reachable when user isn't authenticated */}
              <Route element={<OnlyPublicRoute />}>
                <Route element={<IsolatedLayout />}>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordPage />}
                  />
                  <Route path="/signup" element={<SignupPage />} />
                </Route>
              </Route>

              {/* Public pages */}
              <Route path="/logout" element={<LogoutPage />} />
              {/* Login with token */}
              <Route
                path="/login-with-token"
                element={<LoginWithTokenPage />}
              />
              {/* Public with isolated layout */}
              <Route element={<IsolatedLayout />}>
                <Route path="/privacy-policy" element={<PrivacypolicyPage />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* External oauth proxy */}
        <Route path="/external-oauth/:tenant">
          <Route path="huawei">
            <Route index element={<ExternalOauthHuaweiPage />} />
            <Route
              path="callback"
              element={<ExternalOauthHuaweiCallbackPage />}
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};
