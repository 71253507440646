import { MobileNavbarMenuItem } from './MobileNavbarMenuItem';
import { useNavigationMenu } from 'hooks/useNavigationMenu';
import { useNavigate } from 'react-router-dom';

export const MobileNavbarMenu = () => {
  const { navigationMenuItems } = useNavigationMenu();
  const navigate = useNavigate();

  return (
    <>
      <div className="block sm:hidden">
        <div className="flex bg-white font-sans flex-nowrap border-t border-clevergy-border pb-safe">
          {navigationMenuItems.map((item) => {
            if (item === 'separator') {
              return null;
            }

            if (!item.renderCondition && !item.childrenItems) {
              return null;
            }

            if (!item.showAsTabInMobile || !item.to) {
              return null;
            }

            const childMatchingRoutes = item.childrenItems?.flatMap(
              (i) => i.matchingRoutes || [],
            );
            const matchingRoutes = [
              ...(item.matchingRoutes || []),
              ...(childMatchingRoutes || []),
            ];

            return (
              <MobileNavbarMenuItem
                key={item.label}
                onClick={() => item.to && navigate(item.to)}
                icon={item.icon}
                label={item.label}
                badge={item.badge}
                machingRoutes={matchingRoutes}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
