import { useAuthContext } from './AuthContext';
import {
  Configuration as ClevergyApiConfiguration,
  ConsumptionApi,
  ContractsApi,
  EnergyApi,
  InvoicesApi,
  IntegrationsApi,
  HousesApi,
  OptimizeApi,
  SettingsApi,
  SolarApi,
  SmartDevicesApi,
  TicketsApi,
  UsersApi,
} from '@clevergy/api-client';
import {
  AppsApi,
  AuthApi,
  Configuration as ClevergyPortalRoutesApiConfiguration,
  NotificationsApi,
  TenantsApi,
} from '@clevergy/api-client-portal-routes';
import { FC, ReactNode, createContext, useContext } from 'react';

export const ApiContext = createContext<{
  api: {
    apps: AppsApi;
    auth: AuthApi;
    contracts: ContractsApi;
    consumption: ConsumptionApi;
    energy: EnergyApi;
    invoices: InvoicesApi;
    integrations: IntegrationsApi;
    notifications: NotificationsApi;
    houses: HousesApi;
    optimize: OptimizeApi;
    settings: SettingsApi;
    smartDevices: SmartDevicesApi;
    solar: SolarApi;
    tickets: TicketsApi;
    tenants: TenantsApi;
    users: UsersApi;
  };
} | null>(null);

export const ApiProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { refreshToken } = useAuthContext();

  const config = {
    accessToken: async () => {
      const token = await refreshToken();
      return `Bearer ${token}`;
    },
  };

  const clevergyApiConfig = new ClevergyApiConfiguration(config);
  const clevergyPortalRoutesApiConfig =
    new ClevergyPortalRoutesApiConfiguration({
      ...config,
      // basePath:
      //   import.meta.env.MODE === 'development'
      //     ? 'http://localhost:3003'
      //     : 'https://portal.clever.gy',
    });

  const api = {
    apps: new AppsApi(clevergyPortalRoutesApiConfig),
    auth: new AuthApi(clevergyPortalRoutesApiConfig),
    contracts: new ContractsApi(clevergyApiConfig),
    consumption: new ConsumptionApi(clevergyApiConfig),
    energy: new EnergyApi(clevergyApiConfig),
    invoices: new InvoicesApi(clevergyApiConfig),
    integrations: new IntegrationsApi(clevergyApiConfig),
    houses: new HousesApi(clevergyApiConfig),
    notifications: new NotificationsApi(clevergyPortalRoutesApiConfig),
    optimize: new OptimizeApi(clevergyApiConfig),
    settings: new SettingsApi(clevergyApiConfig),
    smartDevices: new SmartDevicesApi(clevergyApiConfig),
    solar: new SolarApi(clevergyApiConfig),
    tickets: new TicketsApi(clevergyApiConfig),
    tenants: new TenantsApi(clevergyPortalRoutesApiConfig),
    users: new UsersApi(clevergyApiConfig),
  };

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
};

export const useApiContext = () => {
  const context = useContext(ApiContext);
  if (context === null) {
    throw Error('useApiContext must be used inside of a ApiContext');
  }
  return context;
};
