import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { TenantLogo } from 'components/TenantLogo';
import { TrackedButton } from 'components/TrackedButton';
import { Wrapper } from 'components/Wrapper';
import { useNotificationsContext } from 'context/NotificationsContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

const ONE_SECOND_IN_MS = 1000;

export const OnBoardingNotificationsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setHasAnsweredNotifications = useAppConfigurationStore(
    (state) => state.setHasAnsweredNotifications,
  );

  const { permissionsStatus, requestNotificationsPermission } =
    useNotificationsContext();

  const [notificationButtonClicked, setNotificationButtonClicked] =
    useState(false);

  const handleActivateNotifications = () => {
    requestNotificationsPermission();

    setTimeout(() => {
      setNotificationButtonClicked(true);
    }, ONE_SECOND_IN_MS);
  };

  const handleContinue = () => {
    setHasAnsweredNotifications(true);
    navigate('/');
  };

  const displayActiveNotifications =
    permissionsStatus &&
    permissionsStatus !== 'unavailable' &&
    !notificationButtonClicked;

  return (
    <Page>
      <PageHeader title={t('onBoardingNotifications.title') as string} />
      <Wrapper>
        <div className="flex flex-col gap-4 h-full">
          <p>{t('onBoardingNotifications.subtitle')}</p>
          <div className="grow flex flex-col justify-center">
            <div className="relative p-4">
              <div className="flex flex-col items-center w-full gap-4 z-20">
                <FakeNotification
                  title="¡Tu contrato está listo!"
                  subtitle="Entra ahora y descubre cómo ahorrar en tu factura de la luz."
                  time="3m ago"
                />
                <FakeNotification
                  title="¡Ya tienes disponible tu factura!"
                  subtitle="Puedes consultar el resumen de tu factura desde la sección de Facturas"
                  time="3m ago"
                />
                <FakeNotification
                  title="Has alcanzado tu límite de gasto"
                  subtitle="Has alcanzado 334 kWh, el consumo límite que habías establecido durante este mes."
                  time="3m ago"
                />
              </div>
              <div className="absolute h-full bottom-0 left-0 right-0 bg-gradient-to-b from-transparent to-white z-10"></div>
            </div>
          </div>

          <div className="grow"></div>

          <TrackedButton
            as="a"
            fullWidth
            colorScheme="primary"
            variant="flat"
            onClick={handleContinue}
            event={{
              event_type: 'notification_skipped',
            }}
            testId="skip-notifications-button"
          >
            <>{t('onBoardingNotifications.skipStep')}</>
          </TrackedButton>

          {displayActiveNotifications ? (
            <TrackedButton
              fullWidth
              colorScheme="primary"
              onClick={handleActivateNotifications}
              event={{
                event_type: 'notification_activated',
              }}
            >
              <>{t('onBoardingNotifications.active')}</>
            </TrackedButton>
          ) : (
            <TrackedButton
              fullWidth
              colorScheme="primary"
              onClick={handleContinue}
              event={{
                event_type: 'notification_continue',
              }}
            >
              <>{t('onBoardingNotifications.continue')}</>
            </TrackedButton>
          )}
        </div>
      </Wrapper>
    </Page>
  );
};

const FakeNotification = (props: {
  title: string;
  subtitle: string;
  time: string;
}) => {
  return (
    <div className="w-full bg-white shadow-moduleContainer rounded-lg p-2">
      <div className="flex gap-4">
        <div className="shrink-0">
          <div className="w-8 h-8 bg-gray-100 rounded-md overflow-hidden ">
            <TenantLogo type="favicon" width={32} height={32} />
          </div>
        </div>
        <div className="flex flex-col relative w-full gap-1">
          <p className="flex gap-2">
            <span className="flex-grow font-semibold text-sm leading-tight">
              {props.title}
            </span>
            <span className="text-xs text-gray-400 whitespace-nowrap">
              {props.time}
            </span>
          </p>
          <p className="text-xs text-gray-400">{props.subtitle}</p>
        </div>
      </div>
    </div>
  );
};
