import clsx from 'clsx';
import { Children, FC, ReactElement, ReactNode } from 'react';

export type TabsProps = {
  centered?: boolean;
  children: ReactElement<typeof TabsItem> | ReactElement<typeof TabsItem>[];
};

type TabsItemProps = {
  isCurrent?: boolean;
  onClick: () => void;

  children: ReactNode;
};

const Tabs: FC<TabsProps> & { Item: typeof TabsItem } = (props: TabsProps) => {
  const { centered, children } = props;

  return (
    <div className="flex flex-col w-full">
      <div
        className={clsx('flex gap-2 py-1', {
          'w-full justify-between': !centered,
          'mx-auto': centered,
        })}
      >
        {Children.map(children, (child) => {
          return child;
        })}
      </div>
    </div>
  );
};

const TabsItem: FC<TabsItemProps> = (props) => {
  const { isCurrent, onClick, children } = props;

  return (
    <button
      type="button"
      className="text-clevergy-subtext text-base  border-b-2 border-transparent aria-pressed:text-clevergy-primary aria-pressed:border-clevergy-primary"
      aria-pressed={isCurrent}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

Tabs.Item = TabsItem;

export { Tabs };
