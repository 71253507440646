/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { EnergyItemBattery } from './EnergyItemBattery';
import {
  EnergyItemBatteryFromJSON,
  EnergyItemBatteryFromJSONTyped,
  EnergyItemBatteryToJSON,
  EnergyItemBatteryToJSONTyped,
} from './EnergyItemBattery';
import type { EnergyItemEnergyCommunitiesInner } from './EnergyItemEnergyCommunitiesInner';
import {
  EnergyItemEnergyCommunitiesInnerFromJSON,
  EnergyItemEnergyCommunitiesInnerFromJSONTyped,
  EnergyItemEnergyCommunitiesInnerToJSON,
  EnergyItemEnergyCommunitiesInnerToJSONTyped,
} from './EnergyItemEnergyCommunitiesInner';
import type { EnergyItemGrid } from './EnergyItemGrid';
import {
  EnergyItemGridFromJSON,
  EnergyItemGridFromJSONTyped,
  EnergyItemGridToJSON,
  EnergyItemGridToJSONTyped,
} from './EnergyItemGrid';
import type { EnergyItemHouse } from './EnergyItemHouse';
import {
  EnergyItemHouseFromJSON,
  EnergyItemHouseFromJSONTyped,
  EnergyItemHouseToJSON,
  EnergyItemHouseToJSONTyped,
} from './EnergyItemHouse';
import type { EnergyItemSmartDevicesInner } from './EnergyItemSmartDevicesInner';
import {
  EnergyItemSmartDevicesInnerFromJSON,
  EnergyItemSmartDevicesInnerFromJSONTyped,
  EnergyItemSmartDevicesInnerToJSON,
  EnergyItemSmartDevicesInnerToJSONTyped,
} from './EnergyItemSmartDevicesInner';
import type { EnergyItemSolar } from './EnergyItemSolar';
import {
  EnergyItemSolarFromJSON,
  EnergyItemSolarFromJSONTyped,
  EnergyItemSolarToJSON,
  EnergyItemSolarToJSONTyped,
} from './EnergyItemSolar';

/**
 *
 * @export
 * @interface EnergyItem
 */
export interface EnergyItem {
  /**
   *
   * @type {Date}
   * @memberof EnergyItem
   */
  date: Date;
  /**
   *
   * @type {EnergyItemSolar}
   * @memberof EnergyItem
   */
  solar?: EnergyItemSolar;
  /**
   *
   * @type {EnergyItemHouse}
   * @memberof EnergyItem
   */
  house?: EnergyItemHouse;
  /**
   *
   * @type {EnergyItemGrid}
   * @memberof EnergyItem
   */
  grid?: EnergyItemGrid;
  /**
   *
   * @type {EnergyItemBattery}
   * @memberof EnergyItem
   */
  battery?: EnergyItemBattery;
  /**
   *
   * @type {Array<EnergyItemEnergyCommunitiesInner>}
   * @memberof EnergyItem
   */
  energyCommunities?: Array<EnergyItemEnergyCommunitiesInner>;
  /**
   *
   * @type {Array<EnergyItemSmartDevicesInner>}
   * @memberof EnergyItem
   */
  smartDevices?: Array<EnergyItemSmartDevicesInner>;
  /**
   *
   * @type {number}
   * @memberof EnergyItem
   */
  fixedCost?: number;
}

/**
 * Check if a given object implements the EnergyItem interface.
 */
export function instanceOfEnergyItem(value: object): value is EnergyItem {
  if (!('date' in value) || value['date'] === undefined) return false;
  return true;
}

export function EnergyItemFromJSON(json: any): EnergyItem {
  return EnergyItemFromJSONTyped(json, false);
}

export function EnergyItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItem {
  if (json == null) {
    return json;
  }
  return {
    date: new Date(json['date']),
    solar:
      json['solar'] == null
        ? undefined
        : EnergyItemSolarFromJSON(json['solar']),
    house:
      json['house'] == null
        ? undefined
        : EnergyItemHouseFromJSON(json['house']),
    grid:
      json['grid'] == null ? undefined : EnergyItemGridFromJSON(json['grid']),
    battery:
      json['battery'] == null
        ? undefined
        : EnergyItemBatteryFromJSON(json['battery']),
    energyCommunities:
      json['energyCommunities'] == null
        ? undefined
        : (json['energyCommunities'] as Array<any>).map(
            EnergyItemEnergyCommunitiesInnerFromJSON,
          ),
    smartDevices:
      json['smartDevices'] == null
        ? undefined
        : (json['smartDevices'] as Array<any>).map(
            EnergyItemSmartDevicesInnerFromJSON,
          ),
    fixedCost: json['fixedCost'] == null ? undefined : json['fixedCost'],
  };
}

export function EnergyItemToJSON(json: any): EnergyItem {
  return EnergyItemToJSONTyped(json, false);
}

export function EnergyItemToJSONTyped(
  value?: EnergyItem | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    date: value['date'].toISOString(),
    solar: EnergyItemSolarToJSON(value['solar']),
    house: EnergyItemHouseToJSON(value['house']),
    grid: EnergyItemGridToJSON(value['grid']),
    battery: EnergyItemBatteryToJSON(value['battery']),
    energyCommunities:
      value['energyCommunities'] == null
        ? undefined
        : (value['energyCommunities'] as Array<any>).map(
            EnergyItemEnergyCommunitiesInnerToJSON,
          ),
    smartDevices:
      value['smartDevices'] == null
        ? undefined
        : (value['smartDevices'] as Array<any>).map(
            EnergyItemSmartDevicesInnerToJSON,
          ),
    fixedCost: value['fixedCost'],
  };
}
