import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { TenantLogo } from 'components/TenantLogo';
import { useAppSettings } from 'context/AppSettingsContext';
import { useUserHouses } from 'context/UserHousesContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

/**
 * NOTE:
 * This component sets the selected house
 * Is not intended to be used outside the UserSetupContext.
 */
export const HousePicker: FC = () => {
  const { t } = useTranslation();
  const { houses } = useUserHouses();
  const { selectedHouse } = useSelectedHouse();
  const { appSettings } = useAppSettings();

  const isMultiTenant = !appSettings.defaultTenantGcpId;

  if (!selectedHouse && !isMultiTenant) {
    return null;
  }

  return (
    <div className="flex w-full gap-2 items-center py-2">
      {selectedHouse && (
        <>
          {houses && houses.length > 1 ? (
            <Link to="/houses" className="text-clevergy-primary">
              <FontIcon name="arrow_back_ios_new" size={24} />
            </Link>
          ) : (
            <div className="shrink-0 sm:hidden w-8" />
          )}
          <div className="min-w-0 grow text-base sm:text-sm text-center sm:text-left truncate">
            {selectedHouse?.details.address || t('housePicker.noAddress')}
          </div>
        </>
      )}
      <div className="shrink-0 sm:hidden w-8">
        {isMultiTenant && <TenantLogo type="small" width={32} height={32} />}
      </div>
    </div>
  );
};
