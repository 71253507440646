/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface SolarSavings
 */
export interface SolarSavings {
  /**
   * Id of the house
   * @type {string}
   * @memberof SolarSavings
   */
  houseId: string;
  /**
   *
   * @type {Date}
   * @memberof SolarSavings
   */
  date: Date;
  /**
   *
   * @type {number}
   * @memberof SolarSavings
   */
  savings: number;
}

/**
 * Check if a given object implements the SolarSavings interface.
 */
export function instanceOfSolarSavings(value: object): value is SolarSavings {
  if (!('houseId' in value) || value['houseId'] === undefined) return false;
  if (!('date' in value) || value['date'] === undefined) return false;
  if (!('savings' in value) || value['savings'] === undefined) return false;
  return true;
}

export function SolarSavingsFromJSON(json: any): SolarSavings {
  return SolarSavingsFromJSONTyped(json, false);
}

export function SolarSavingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SolarSavings {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    date: new Date(json['date']),
    savings: json['savings'],
  };
}

export function SolarSavingsToJSON(json: any): SolarSavings {
  return SolarSavingsToJSONTyped(json, false);
}

export function SolarSavingsToJSONTyped(
  value?: SolarSavings | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    date: value['date'].toISOString(),
    savings: value['savings'],
  };
}
