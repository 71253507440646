import { AddAuthorizedUserModal } from './components/AddAuthorizedUserModal';
import { EmptyScreen } from './components/EmptyScreen';
import { NotOwnerScreen } from './components/NotOwnerScreen';
import { RevokeAccessModal } from './components/RevokeAccessModal';
import { AuthorizedUserStatusEnum } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { skipToken, useQuery, useQueryClient } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SharedAccessPage = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const queryClient = useQueryClient();
  const { selectedHouse } = useSelectedHouse();

  // States
  const [showAddAuthorizedUserModal, setShowAddAuthorizedUserModal] =
    useState(false);
  const [userAuthorizedEmailToRevoke, setUserAuthorizedEmailToRevoke] =
    useState<string | null>(null);

  // Get all authorized users for the authed user
  const authorizedUsersQuery = useQuery({
    queryKey: [
      QueryKeys.GET_AUTHORIZED_USERS,
      { userId: authedUser?.uid, houseId: selectedHouse?.details.houseId },
    ],
    queryFn: authedUser?.uid
      ? () =>
          api.users.getUsersAuthorizedByUser({
            userId: authedUser?.uid,
          })
      : skipToken,
  });

  // Get all authorized users for the selected house
  const authorizedUsersForSelectedHouse = useMemo(() => {
    return authorizedUsersQuery.data?.filter(
      (user) =>
        user.houseId === selectedHouse?.details.houseId &&
        user.status === AuthorizedUserStatusEnum.Authorized,
    );
  }, [authorizedUsersQuery.data, selectedHouse?.details.houseId]);

  // Handle on access revoked
  const handleOnAuthorizedUsersChanged = () => {
    queryClient.invalidateQueries({
      queryKey: [
        QueryKeys.GET_AUTHORIZED_USERS,
        { userId: authedUser?.uid, houseId: selectedHouse?.details.houseId },
      ],
    });
    setShowAddAuthorizedUserModal(false);
    setUserAuthorizedEmailToRevoke(null);
  };

  const handleOnAddAuthorizedUser = () => {
    setShowAddAuthorizedUserModal(true);
  };

  const handleOnRevokeAccess = (userAuthorizedEmail: string) => {
    setUserAuthorizedEmailToRevoke(userAuthorizedEmail);
    setShowAddAuthorizedUserModal(false);
  };

  if (authorizedUsersQuery.isPending || authorizedUsersQuery.isFetching) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('sharedAccess.headerTitle') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <PullToRefresh />

      <Wrapper>
        {selectedHouse?.houseMetadata.isOwner && (
          <>
            {authorizedUsersForSelectedHouse?.length === 0 ? (
              <EmptyScreen onAddAuthorizedUser={handleOnAddAuthorizedUser} />
            ) : (
              <>
                <p className="mb-4">
                  {t('sharedAccess.authorizedUsersScreen.intro')}
                </p>
                <div className="flex flex-col">
                  {authorizedUsersForSelectedHouse?.map((user) => (
                    <div
                      key={user.authorizedUserEmail}
                      className="flex w-full gap-2 items-center justify-between py-3 border-b border-l-clevergy-border"
                    >
                      <div>{user.authorizedUserEmail}</div>
                      <button
                        className="text-red-500 cursor-pointer"
                        onClick={() =>
                          handleOnRevokeAccess(user.authorizedUserEmail)
                        }
                      >
                        {t(
                          'sharedAccess.authorizedUsersScreen.revokeAccessButton',
                        )}
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={handleOnAddAuthorizedUser}
                    className="flex gap-2 text-clevergy-primary py-3 border-b border-l-clevergy-border w-full"
                  >
                    <FontIcon name="person_add" />
                    {t(
                      'sharedAccess.authorizedUsersScreen.addAuthorizedUserButton',
                    )}
                  </button>
                </div>
              </>
            )}

            {userAuthorizedEmailToRevoke && selectedHouse?.details.houseId && (
              <RevokeAccessModal
                onCancel={() => setUserAuthorizedEmailToRevoke(null)}
                onAccessRevoked={handleOnAuthorizedUsersChanged}
                userAuthorizedEmail={userAuthorizedEmailToRevoke}
                houseId={selectedHouse?.details.houseId}
                houseName={selectedHouse?.details.address}
              />
            )}

            {showAddAuthorizedUserModal && selectedHouse?.details.houseId && (
              <AddAuthorizedUserModal
                onCancel={() => setShowAddAuthorizedUserModal(false)}
                onAccessAdded={handleOnAuthorizedUsersChanged}
                houseId={selectedHouse?.details.houseId}
                houseName={selectedHouse?.details.address}
              />
            )}
          </>
        )}
        {selectedHouse && !selectedHouse.houseMetadata.isOwner && (
          <NotOwnerScreen />
        )}
      </Wrapper>
    </Page>
  );
};
