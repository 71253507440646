import clsx from 'clsx';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import { ReactNode } from 'react';
import { matchRoutes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

type MobileNavbarMenuItemProps = {
  onClick: () => void;
  icon: ReactNode;
  label: string;
  badge?: number;
  machingRoutes: string[];
};

export const MobileNavbarMenuItem: FC<MobileNavbarMenuItemProps> = ({
  onClick,
  icon,
  label,
  badge = 0,
  machingRoutes,
}) => {
  const location = useLocation();
  const isActive = matchRoutes(
    machingRoutes.map((route) => ({ path: route })),
    location,
  );

  return (
    <AnimatePresence key={label} mode="popLayout">
      <button
        onClick={onClick}
        className={clsx(
          'flex flex-col items-center justify-center gap-0.5 text-xs w-full px-2 py-3 cursor-pointer transition-colors duration-200',
          {
            'text-clevergy-primary': isActive,
            'text-clevergy-subtext': !isActive,
          },
        )}
        aria-current={isActive ? 'page' : 'false'}
      >
        <div className="relative">
          {icon}
          {badge > 0 && (
            <motion.span
              className="absolute top-0 -right-[14px] flex items-center justify-center bg-clevergy-secondary text-white font-semibold rounded-full text-[10px] w-4 h-4"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ scale: 0 }}
            >
              {badge}
            </motion.span>
          )}
        </div>
        {label}
      </button>
    </AnimatePresence>
  );
};
