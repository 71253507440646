import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import {
  NavigationMenuItemDescription,
  useNavigationMenu,
} from 'hooks/useNavigationMenu';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppMenusStore } from 'stores/appMenusStore';

const GlobalSideBarMenuVariants = {
  open: {
    right: 0,
    top: 0,
    opacity: 1,
  },
  closed: {
    right: '-100%',
    top: 0,
    opacity: 0,
  },
};

export const GlobalSideBarMenu: FC = () => {
  const { navigationMenuItems } = useNavigationMenu({
    ignoreSelectedHouse: true,
  });
  const navigate = useNavigate();
  const { isDesktopSideBarMenuOpen, toggleDesktopSideBarMenu } =
    useAppMenusStore();

  useEffect(() => {
    if (isDesktopSideBarMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isDesktopSideBarMenuOpen]);

  return (
    <>
      <motion.div
        className={clsx({
          'fixed top-0 inset-0 backdrop-blur-sm z-10': isDesktopSideBarMenuOpen,
        })}
        variants={{
          open: {
            opacity: 1,
          },
          closed: {
            opacity: 0,
          },
        }}
        initial="closed"
        animate={isDesktopSideBarMenuOpen ? 'open' : 'closed'}
        transition={{
          duration: 0.3,
        }}
        onClick={() => toggleDesktopSideBarMenu()}
      ></motion.div>
      <motion.div
        initial="closed"
        variants={GlobalSideBarMenuVariants}
        animate={isDesktopSideBarMenuOpen ? 'open' : 'closed'}
        className="fixed top-0 right-0 h-full w-full md:w-2/5 md:max-w-lg bg-white z-50 overflow-y-auto shadow-md md:shadow-none"
      >
        <div className="flex justify-start pt-safe-or-2 pb-2 pl-safe-or-2 pr-safe-or-2 sticky top-0 bg-white">
          <button onClick={() => toggleDesktopSideBarMenu()}>
            <FontIcon name="close" />
          </button>
        </div>
        <div className="pb-safe">
          <List>
            <>
              {navigationMenuItems.map((item) => {
                if (item === 'separator') {
                  return null;
                }

                if (!item.renderCondition && !item.childrenItems) {
                  return null;
                }

                const handleClick = (
                  targetItem: NavigationMenuItemDescription,
                ) => {
                  if (targetItem.to) {
                    navigate(targetItem.to);
                  }

                  toggleDesktopSideBarMenu();
                };

                if (item.childrenItems && item.childrenItems.length > 0) {
                  return item.childrenItems.map((childItem) => (
                    <GlobalSideBarMenuItem
                      key={childItem.label}
                      itemDescription={childItem}
                      onClick={() => handleClick(childItem)}
                    />
                  ));
                }

                return (
                  <GlobalSideBarMenuItem
                    key={item.label}
                    itemDescription={item}
                    onClick={() => handleClick(item)}
                  />
                );
              })}
            </>
          </List>
        </div>
      </motion.div>
    </>
  );
};

const GlobalSideBarMenuItem = (props: {
  itemDescription: NavigationMenuItemDescription;
  onClick: () => void;
}) => {
  const { itemDescription, onClick } = props;

  if (!itemDescription.renderCondition) {
    return null;
  }

  if (itemDescription.showAsTabInMobile) {
    return null;
  }

  if (!itemDescription.to) {
    return null;
  }

  return (
    <List.Item
      leftIcon={itemDescription.icon}
      rightIcon={<FontIcon name="chevron_right" />}
      onClick={onClick}
    >
      {itemDescription.label}
    </List.Item>
  );
};
