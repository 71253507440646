/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CumulativeConsumption
 */
export interface CumulativeConsumption {
  /**
   *
   * @type {number}
   * @memberof CumulativeConsumption
   */
  currentConsumption?: number;
  /**
   *
   * @type {number}
   * @memberof CumulativeConsumption
   */
  estimatedConsumption?: number;
  /**
   *
   * @type {number}
   * @memberof CumulativeConsumption
   */
  previousYearConsumption?: number;
}

/**
 * Check if a given object implements the CumulativeConsumption interface.
 */
export function instanceOfCumulativeConsumption(
  value: object,
): value is CumulativeConsumption {
  return true;
}

export function CumulativeConsumptionFromJSON(
  json: any,
): CumulativeConsumption {
  return CumulativeConsumptionFromJSONTyped(json, false);
}

export function CumulativeConsumptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CumulativeConsumption {
  if (json == null) {
    return json;
  }
  return {
    currentConsumption:
      json['currentConsumption'] == null
        ? undefined
        : json['currentConsumption'],
    estimatedConsumption:
      json['estimatedConsumption'] == null
        ? undefined
        : json['estimatedConsumption'],
    previousYearConsumption:
      json['previousYearConsumption'] == null
        ? undefined
        : json['previousYearConsumption'],
  };
}

export function CumulativeConsumptionToJSON(json: any): CumulativeConsumption {
  return CumulativeConsumptionToJSONTyped(json, false);
}

export function CumulativeConsumptionToJSONTyped(
  value?: CumulativeConsumption | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    currentConsumption: value['currentConsumption'],
    estimatedConsumption: value['estimatedConsumption'],
    previousYearConsumption: value['previousYearConsumption'],
  };
}
