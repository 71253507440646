import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type LegalDocument = {
  format: 'pdf';
  url: string;
  open: () => void;
};

export type LegalDocuments = {
  privacyPolicy: LegalDocument | null;
  termsOfService: LegalDocument | null;
};

export const useLegalDocuments = () => {
  const { tenantId } = useTenantSettings();
  const {
    i18n: { language },
  } = useTranslation();
  const [isPending, setIsPending] = useState(true);

  const [legalDocuments, setLegalDocuments] = useState<LegalDocuments>({
    privacyPolicy: null,
    termsOfService: null,
  });

  useEffect(() => {
    const legalPrivacyPdfUrl = `https://assets.clever.gy/tenants/${tenantId}/legal-privacy.${language}.pdf`;
    const legalTermsOfServicePdfUrl = `https://assets.clever.gy/tenants/${tenantId}/legal-terms.${language}.pdf`;

    // Check if the file exists
    fetch(legalPrivacyPdfUrl, { method: 'HEAD' })
      .then((response) => {
        if (response.headers.get('content-type')?.includes('application/pdf')) {
          setLegalDocuments((prev) => ({
            ...prev,
            privacyPolicy: {
              format: 'pdf',
              url: legalPrivacyPdfUrl,
              open: () => openDocument(legalPrivacyPdfUrl),
            },
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });

    fetch(legalTermsOfServicePdfUrl, { method: 'HEAD' })
      .then((response) => {
        if (response.headers.get('content-type')?.includes('application/pdf')) {
          setLegalDocuments((prev) => ({
            ...prev,
            termsOfService: {
              format: 'pdf',
              url: legalTermsOfServicePdfUrl,
              open: () => openDocument(legalTermsOfServicePdfUrl),
            },
          }));
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsPending(false);
      });
  }, [tenantId, language]);

  const openDocument = (url: string) => {
    Device.getInfo().then((info) => {
      if (info.platform === 'android') {
        Browser.open({
          url: `https://docs.google.com/gview?url=${url}`,
        });
      } else {
        Browser.open({ url });
      }
    });
  };

  return { isPending, legalDocuments, openDocument };
};
