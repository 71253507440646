import { useApiContext } from './ApiContext';
import { useAuthContext } from './AuthContext';
import { UserIntegrationsResponse } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { skipToken, useQuery } from '@tanstack/react-query';
import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

export type UserIntegrationsContextValue = {
  userIntegrations: UserIntegrationsResponse | undefined;
  refetch: () => Promise<void>;
};

export const UserIntegrationsContext =
  createContext<UserIntegrationsContextValue | null>(null);

export const UserIntegrationsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  // Query for user integrations
  const userIntegrationsQuery = useQuery({
    queryKey: [QueryKeys.GET_USER_INTEGRATIONS, { userId: authedUser?.uid }],
    queryFn: authedUser?.uid
      ? () => api.users.getUserIntegrations({ userId: authedUser?.uid })
      : skipToken,
    refetchInterval: 1000 * 60, // 1 minute
  });

  const value: UserIntegrationsContextValue = useMemo(
    () => ({
      userIntegrations: userIntegrationsQuery.data,
      refetch: async () => {
        userIntegrationsQuery.refetch();
      },
    }),
    [userIntegrationsQuery],
  );

  return (
    <UserIntegrationsContext.Provider value={value}>
      {children}
    </UserIntegrationsContext.Provider>
  );
};

export const useUserIntegrationsContext = () => {
  const context = useContext(UserIntegrationsContext);
  if (context === null) {
    throw Error(
      'useUserIntegrationsContext must be used inside of a UserIntegrationsContext',
    );
  }
  return context;
};
