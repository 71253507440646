import { TicketResume } from './components/TicketResume';
import { TicketsNoData } from './components/TicketsNoData';
import { TicketsResponseStatusEnum } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { skipToken, useQuery } from '@tanstack/react-query';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const THIRTY_SECONDS = 30 * 1000;

export const SupportTicketsPage: FC = () => {
  const { t } = useTranslation();
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();

  const ticketsQuery = useQuery({
    queryKey: [QueryKeys.GET_TICKETS],
    queryFn: authedUser?.uid
      ? () =>
          api.tickets.getTickets({
            userId: authedUser.uid,
          })
      : skipToken,
    staleTime: 0,
    refetchOnMount: true,
    refetchInterval: THIRTY_SECONDS,
  });

  const tickets = useMemo(() => {
    if (!ticketsQuery.data) {
      return [];
    }

    const openTicketsList = ticketsQuery.data.filter(
      (ticket) => ticket.status !== TicketsResponseStatusEnum.Solved,
    );

    const solvedTicketsList = ticketsQuery.data.filter(
      (ticket) => ticket.status === TicketsResponseStatusEnum.Solved,
    );

    return [...openTicketsList, ...solvedTicketsList];
  }, [ticketsQuery.data]);

  const displayNoData =
    !ticketsQuery.isPending && (!tickets || tickets.length === 0);

  const displayTickets = !ticketsQuery.isPending && tickets.length > 0;

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        backButtonPath="/support"
        title={t('support.yourTickets.title') as string}
      />
      <PullToRefresh />
      <Wrapper>
        {ticketsQuery.isPending && (
          <LoadingIndicator fullScreen="transparent" />
        )}

        {displayNoData && <TicketsNoData />}

        {displayTickets && (
          <div className="flex flex-col justify-between items-center gap-2">
            {tickets.map((ticket) => (
              <TicketResume key={ticket.id} ticket={ticket} />
            ))}
          </div>
        )}
      </Wrapper>
    </Page>
  );
};
