import { TenantSettingsEnergyPricesTypeEnum } from '@clevergy/api-client-portal-routes';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { isClevergyEmail } from '@clevergy/utils/validations/isClevergyEmail';
import { skipToken, useQuery } from '@tanstack/react-query';
import { AppAlerts } from 'components/AppAlerts';
import { DraftModuleHouseComparison } from 'components/DraftModuleHouseComparison';
import { HouseContractAlerts } from 'components/HouseContractAlerts';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { SmartMeterBanner } from 'components/SmartMeterBanner';
import { TenantLoadingIndicator } from 'components/TenantLoadingIndicator';
import { Wrapper } from 'components/Wrapper';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { useUserInfo } from 'context/UserInfoContext';
import { format } from 'date-fns';
import { useNavigationMenu } from 'hooks/useNavigationMenu';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useMemo } from 'react';

const tooltipHouseComparison = `
<ul>
  <li>
    <strong>Hogares eficientes:</strong>
    consumo medio de hogares con
    características similares según tu configuración de la cuenta,
    teniendo en cuenta solo el 30% de los hogares con menor consumo.
  </li>
  <li>
    <strong>Hogares similares:</strong>
    consumo medio de hogares con características similares según tu configuración de la cuenta.
  </li>
  <li>
    <strong>Tus vecinos:</strong>
    consumo medio de hogares con el mismo código postal que tú.
  </li>
</ul>
`;

export const HomePage: FC = () => {
  const { token } = useAuthContext();
  const { userInfo } = useUserInfo();
  const { selectedHouse } = useSelectedHouse();
  const { tenantId } = useTenantSettings();
  const { energyPricesType: energyPricesTypeFromTenant } = useTenantSettings();
  const { showInvoicesTabInMobile } = useNavigationMenu();

  const { api } = useApiContext();

  const houseContractQuery = useQuery({
    queryKey: [
      QueryKeys.HOUSE_CONTRACT,
      {
        houseId: selectedHouse?.details.houseId,
      },
    ],
    queryFn:
      selectedHouse?.details.houseId &&
      energyPricesTypeFromTenant === TenantSettingsEnergyPricesTypeEnum.Contract
        ? () =>
            api.contracts.getHouseLastContract({
              houseId: selectedHouse?.details.houseId,
            })
        : skipToken,
  });

  const energyPricesType: 'NONE' | 'PVPC' | 'OMIE' | undefined = useMemo(() => {
    if (
      energyPricesTypeFromTenant === TenantSettingsEnergyPricesTypeEnum.Contract
    ) {
      if (houseContractQuery.data?.margin) {
        return 'OMIE';
      }

      if (houseContractQuery.data?.pvpc) {
        return 'PVPC';
      }

      return 'NONE';
    }

    return energyPricesTypeFromTenant;
  }, [
    energyPricesTypeFromTenant,
    houseContractQuery.data?.margin,
    houseContractQuery.data?.pvpc,
  ]);

  return (
    <Page>
      <PageHeader withHousePicker />
      <PullToRefresh />
      <Wrapper>
        {!token && <TenantLoadingIndicator />}

        {token && (
          <>
            <HouseContractAlerts />

            <SmartMeterBanner />

            <AppAlerts />

            {selectedHouse && (
              <clevergy-solar-real-time
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-power-chart
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
                data-current-date={format(new Date(), 'yyyy-MM-dd')}
              />
            )}

            {selectedHouse && isClevergyEmail(userInfo.email) && (
              <clevergy-battery
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-solar-info
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && isClevergyEmail(userInfo.email) && (
              <clevergy-solar-amortization
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-smart-devices
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {energyPricesType &&
              energyPricesType !== TenantSettingsEnergyPricesTypeEnum.None && (
                <clevergy-energy-prices
                  data-show-energy-price-surplus={
                    selectedHouse?.houseMetadata.lastDatePower
                      ? 'true'
                      : 'false'
                  }
                  data-show-energy-price-list={
                    tenantId === 'selectra' ? 'true' : 'false'
                  }
                  data-energy-prices-type={energyPricesType}
                />
              )}

            {selectedHouse && (
              <clevergy-consumption-cumulative
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
                data-tooltip={''}
              />
            )}

            {selectedHouse && (
              <clevergy-invoice-viewer
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
                data-show-empty-state={showInvoicesTabInMobile}
              />
            )}

            {selectedHouse && (
              <clevergy-virtual-wallet
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-virtual-battery
                data-token={token}
                data-house-id={selectedHouse.details.houseId}
              />
            )}

            {selectedHouse &&
              !userInfo.isCompany &&
              tenantId !== 'comunidad-solar' && (
                <clevergy-house-comparison
                  data-token={token}
                  data-house-id={selectedHouse.details.houseId}
                  data-view="race"
                  data-variant="advanced"
                  data-tooltip={tooltipHouseComparison}
                />
              )}

            {/* Draft modules */}
            <DraftModuleHouseComparison />
          </>
        )}
      </Wrapper>
    </Page>
  );
};
