import { DoubleKpi } from './DoubleKpi';
import { EntitiesLogos } from './EntitiesLogos';
import { Header } from './Header';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { ModuleCard } from '@clevergy/ui/components/ModuleCard';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { format, startOfMonth, startOfYear } from 'date-fns';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useTranslation } from 'react-i18next';

export const BoardContent = () => {
  const { token } = useAuthContext();
  const { selectedHouse } = useSelectedHouse();
  const { api } = useApiContext();
  const { t } = useTranslation();

  const today = new Date();
  const currentMonthStartDate = startOfMonth(today);
  const currentYearStartDate = startOfYear(today);
  const houseId = selectedHouse?.details.houseId;

  const currentMonthSolarInfoQuery = useQuery({
    queryKey: [
      'QueryKeys.SOLAR_INFO',
      {
        houseId,
        startDate: format(currentMonthStartDate, 'd MMM yyyy'),
        endDate: format(today, 'd MMM yyyy'),
      },
    ],
    queryFn: houseId
      ? () =>
          api.solar.getSolarInfo({
            houseId,
            startDate: currentMonthStartDate,
            endDate: today,
          })
      : skipToken,
    refetchInterval: 60 * 60 * 1000, // 1 hour
  });

  const currentYearSolarInfoQuery = useQuery({
    queryKey: [
      'QueryKeys.SOLAR_INFO2',
      {
        houseId,
        startDate: format(currentYearStartDate, 'd MMM yyyy'),
        endDate: format(today, 'd MMM yyyy'),
      },
    ],
    queryFn: houseId
      ? () =>
          api.solar.getSolarInfo({
            houseId,
            startDate: currentYearStartDate,
            endDate: today,
          })
      : skipToken,
    refetchInterval: 60 * 60 * 1000, // 1 hour
  });

  if (!token || !selectedHouse || currentMonthSolarInfoQuery.isPending) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  return (
    <div className="max-w-screen-xl mx-auto overflow-y-auto">
      <Header />
      <div className="grid grid-1 md:grid-cols-3 gap-4">
        <div className="col-span-1">
          <clevergy-solar-real-time
            data-token={token}
            data-house-id={houseId}
          />
        </div>
        <div className="col-span-1">
          <clevergy-power-chart
            data-token={token}
            data-house-id={houseId}
            data-current-date={format(new Date(), 'yyyy-MM-dd')}
          />
        </div>
        <div className="col-span-1">
          <clevergy-solar-summary
            data-token={token}
            data-house-id={houseId}
            data-show-dates-selector="false"
            data-date-range-type="month"
            data-date-range-current-date={format(new Date(), 'yyyy-MM-dd')}
            data-title={t('kioskMode.board.solarSummaryModule.title')}
          />
        </div>
        <div className="col-span-1">
          <ModuleCard>
            <ModuleCard.Header
              title={t('kioskMode.board.consumptionCumulativeKpi.title')}
              icon={
                <span className="text-clevergy-primary">
                  <FontIcon name="home" />
                </span>
              }
            />
            <ModuleCard.Content>
              <DoubleKpi
                kpiLeft={{
                  title: t(
                    'kioskMode.board.consumptionCumulativeKpi.kpiLeft.title',
                  ),
                  value: currentMonthSolarInfoQuery.data?.selfConsumptionKwh,
                  unit: 'kWh',
                }}
                kpiRight={{
                  title: t(
                    'kioskMode.board.consumptionCumulativeKpi.kpiRight.title',
                  ),
                  value: currentYearSolarInfoQuery.data?.selfConsumptionKwh,
                  unit: 'kWh',
                }}
              />
            </ModuleCard.Content>
          </ModuleCard>
        </div>
        <div className="col-span-1">
          <ModuleCard>
            <ModuleCard.Header
              title={t('kioskMode.board.productionCumulativeKpi.title')}
              icon={
                <span className="text-clevergy-primary">
                  <FontIcon name="solar_power" />
                </span>
              }
            />
            <ModuleCard.Content>
              <DoubleKpi
                kpiLeft={{
                  title: t(
                    'kioskMode.board.productionCumulativeKpi.kpiLeft.title',
                  ),
                  value: currentMonthSolarInfoQuery.data?.selfProductionKwh,
                  unit: t(
                    'kioskMode.board.productionCumulativeKpi.kpiLeft.unit',
                  ),
                }}
                kpiRight={{
                  title: t(
                    'kioskMode.board.productionCumulativeKpi.kpiRight.title',
                  ),
                  value: currentYearSolarInfoQuery.data?.selfProductionKwh,
                  unit: t(
                    'kioskMode.board.productionCumulativeKpi.kpiRight.unit',
                  ),
                }}
              />
            </ModuleCard.Content>
          </ModuleCard>
        </div>
        <div className="col-span-1">
          <ModuleCard>
            <ModuleCard.Header
              title={t('kioskMode.board.co2ReductionKpi.title')}
              icon={
                <span className="text-clevergy-primary">
                  <FontIcon name="co2" />
                </span>
              }
            />
            <ModuleCard.Content>
              <DoubleKpi
                kpiLeft={{
                  title: t('kioskMode.board.co2ReductionKpi.kpiLeft.title'),
                  value: currentMonthSolarInfoQuery.data?.selfProductionKwh
                    ? currentMonthSolarInfoQuery.data.selfProductionKwh * 0.25
                    : undefined,
                  unit: t('kioskMode.board.co2ReductionKpi.kpiLeft.unit'),
                }}
                kpiRight={{
                  title: t('kioskMode.board.co2ReductionKpi.kpiRight.title'),
                  value: currentYearSolarInfoQuery.data?.selfProductionKwh
                    ? currentYearSolarInfoQuery.data.selfProductionKwh * 0.25
                    : undefined,
                  unit: t('kioskMode.board.co2ReductionKpi.kpiRight.unit'),
                }}
              />
            </ModuleCard.Content>
          </ModuleCard>
        </div>
      </div>
      <EntitiesLogos />
    </div>
  );
};
