import {
  Configuration,
  GetOauthLoginUrlVendorEnum,
  IntegrationsApi,
} from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { ModuleError } from '@clevergy/ui/components/ModuleError';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useExternalOauthProxyStore } from 'stores/externalOauthProxyStore';

export const ExternalOauthHuaweiPage = () => {
  // Get the params from the URL
  const [searchParams] = useSearchParams();
  const jwtFromParams = searchParams.get('jwt');
  const callbackUrlFromParams = searchParams.get('callbackUrl');
  const houseIdFromParams = searchParams.get('houseId');

  // isReady is used to prevent the query from being called before the params are saved
  const [isReady, setIsReady] = useState(false);

  // hasError is used to show an error message if the query fails or params are invalid
  const [hasError, setHasError] = useState(false);

  // Store for saving the params to the local storage for the callback page
  const { setJwt, setCallbackUrl, setHouseId } = useExternalOauthProxyStore();

  // Query for getting the login URL
  const getOauthLoginUrlQuery = useQuery({
    queryKey: [
      QueryKeys.GET_INTEGRATION_OAUTH_LOGIN_URL,
      {
        houseId: houseIdFromParams,
        vendor: GetOauthLoginUrlVendorEnum.HuaweiOauth,
      },
    ],
    queryFn:
      isReady && houseIdFromParams && jwtFromParams
        ? () => {
            const api = new IntegrationsApi(
              new Configuration({
                accessToken: `Bearer ${jwtFromParams}`,
              }),
            );
            return api.getOauthLoginUrl({
              houseId: houseIdFromParams,
              vendor: GetOauthLoginUrlVendorEnum.HuaweiOauth,
            });
          }
        : skipToken,
  });

  // Save the params to the store for the callback page
  useEffect(() => {
    if (!jwtFromParams || !houseIdFromParams) {
      setHasError(true);
      return;
    }
    setJwt(jwtFromParams);
    setHouseId(houseIdFromParams);
    if (callbackUrlFromParams) {
      setCallbackUrl(callbackUrlFromParams);
    }
    setIsReady(true);
  }, [
    setCallbackUrl,
    setHouseId,
    setJwt,
    jwtFromParams,
    callbackUrlFromParams,
    houseIdFromParams,
  ]);

  // Redirect to the login URL
  useEffect(() => {
    if (getOauthLoginUrlQuery.data?.url) {
      window.location.replace(getOauthLoginUrlQuery.data.url);
    }
  }, [getOauthLoginUrlQuery.data]);

  if (getOauthLoginUrlQuery.isError || hasError) {
    return <ModuleError title="Error" message="An error occurred" />;
  }

  return <LoadingIndicator fullScreen="transparent" />;
};
