import styles from './ProcessIconSuccess.module.css';
import { FC } from 'react';

export const ProcessIconSuccess: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      fill="none"
      viewBox="0 0 112 112"
    >
      <circle
        cx="56"
        cy="56"
        r="56"
        fill="#E1F4F2"
        className={styles.ProcessIconSuccess_circle}
      />
      <path
        className={styles.ProcessIconSuccess_checkPath}
        fill="#0d9488"
        fillRule="evenodd"
        d="M79.72 39.747c.729.729.729 1.91 0 2.64L49.853 72.254a1.867 1.867 0 0 1-2.64 0L34.147 59.187a1.867 1.867 0 1 1 2.64-2.64l11.746 11.747L77.08 39.747a1.867 1.867 0 0 1 2.64 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
