import { SplashScreen } from '@capacitor/splash-screen';
import { EventBus, EventBusContextProvider } from '@clevergy/event-bus';
import { ModalProvider } from '@clevergy/ui/context/ModalContext';
import * as Sentry from '@sentry/react';
import { AppRoutes } from 'AppRoutes';
import { AppWrapper } from 'components/AppWrapper';
import { GoogleTagManager } from 'components/GoogleTagManager';
import { LastLogginTracker } from 'components/LastLogginTracker';
import { AnalyticsProvider } from 'context/AnalyticsContext';
import { ApiProvider } from 'context/ApiContext';
import { AppSettingsProvider } from 'context/AppSettingsContext';
import { AppUpdaterProvider } from 'context/AppUpdaterContext';
import { AuthProvider } from 'context/AuthContext';
import { NotificationsProvider } from 'context/NotificationsContext';
import { QueryProvider } from 'context/QueryContext';
import { TranslationsProvider } from 'context/TranslationsContext';
import { UserHousesContextProvider } from 'context/UserHousesContext';
import { UserInfoContextProvider } from 'context/UserInfoContext';
import { UserIntegrationsProvider } from 'context/UserIntegrationsContext';
import { AppAnalyticsListener } from 'listeners/AppAnalyticsListener';
import { AppUrlListener } from 'listeners/AppUrlListener';
import { AppErrorPage } from 'pages/errors/AppErrorPage';
import { FC, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'styles/index.css';

const eventBus = new EventBus();

export const Root: FC = () => {
  // Hide the splash screen when app is ready
  useEffect(() => {
    SplashScreen.hide();
  });

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        console.error(error);
        return (
          <div className="absolute inset-0 flex flex-col justify-between h-full w-full min-h-full">
            <AppErrorPage />
          </div>
        );
      }}
    >
      <AppWrapper>
        <AnalyticsProvider>
          <EventBusContextProvider eventBus={eventBus}>
            <ModalProvider modalRenderElement={document.body}>
              <QueryProvider>
                <AuthProvider>
                  <ApiProvider>
                    <AppSettingsProvider>
                      <TranslationsProvider>
                        <AppUpdaterProvider>
                          <UserIntegrationsProvider>
                            <UserInfoContextProvider>
                              <UserHousesContextProvider>
                                <NotificationsProvider>
                                  <LastLogginTracker />
                                  <GoogleTagManager />
                                  <AppUrlListener />
                                  <AppAnalyticsListener />
                                  <AppRoutes />
                                </NotificationsProvider>
                              </UserHousesContextProvider>
                            </UserInfoContextProvider>
                          </UserIntegrationsProvider>
                        </AppUpdaterProvider>
                      </TranslationsProvider>
                    </AppSettingsProvider>
                  </ApiProvider>
                </AuthProvider>
              </QueryProvider>
            </ModalProvider>
          </EventBusContextProvider>
        </AnalyticsProvider>
      </AppWrapper>
    </Sentry.ErrorBoundary>
  );
};

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export const App = () => {
  return <RouterProvider router={router} />;
};
