import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import { AppVersionInfo } from 'components/AppVersionInfo';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';
import {
  NavigationMenuItemDescription,
  useNavigationMenu,
} from 'hooks/useNavigationMenu';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export const MorePage: FC = () => {
  const navigate = useNavigate();
  const { navigationMenuItems } = useNavigationMenu();

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <Wrapper>
        <div className="flex flex-col p-safe">
          <div className="grow">
            <List>
              <>
                {navigationMenuItems.map((item) => {
                  if (item === 'separator') {
                    return null;
                  }

                  if (!item.renderCondition && !item.childrenItems) {
                    return null;
                  }

                  const handleClick = (
                    targetItem: NavigationMenuItemDescription,
                  ) => {
                    if (targetItem.to) {
                      navigate(targetItem.to);
                    }
                  };

                  if (item.childrenItems && item.childrenItems.length > 0) {
                    return item.childrenItems.map((childItem) => (
                      <MobileNavbarMoreMenuItem
                        key={childItem.label}
                        itemDescription={childItem}
                        onClick={() => handleClick(childItem)}
                      />
                    ));
                  }

                  return (
                    <MobileNavbarMoreMenuItem
                      key={item.label}
                      itemDescription={item}
                      onClick={() => handleClick(item)}
                    />
                  );
                })}
              </>
            </List>
          </div>

          <AppVersionInfo />
        </div>
      </Wrapper>
    </Page>
  );
};

const MobileNavbarMoreMenuItem = (props: {
  itemDescription: NavigationMenuItemDescription;
  onClick: () => void;
}) => {
  const { itemDescription, onClick } = props;

  if (!itemDescription.renderCondition) {
    return null;
  }

  if (itemDescription.showAsTabInMobile) {
    return null;
  }

  if (!itemDescription.to) {
    return null;
  }

  return (
    <List.Item
      leftIcon={itemDescription.icon}
      rightIcon={<FontIcon name="chevron_right" />}
      onClick={onClick}
    >
      {itemDescription.label}
    </List.Item>
  );
};
