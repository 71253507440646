import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { getGcpTenantIdFromToken } from '@clevergy/utils/jwt/getGcpTenantIdFromToken';
import { skipToken, useQuery } from '@tanstack/react-query';
import { TenantPage } from 'components/TenantPage';
import { useApiContext } from 'context/ApiContext';
import { useAppSettings } from 'context/AppSettingsContext';
import { useAuthContext } from 'context/AuthContext';
import { TenantSettingsProvider } from 'context/TenantSettingsContext';
import { TenantCodePage } from 'pages/auth/TenantCodePage';
import { FC, useEffect, useMemo } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

export const MultiTenantRoute: FC = () => {
  const { setAuthTenantGcpId, token } = useAuthContext();
  const [searchParams] = useSearchParams();
  const tokenFromUrl = searchParams.get('token');
  const { api } = useApiContext();
  const { appSettings } = useAppSettings();

  // Stored tenant id for multi tenant
  const { tenantIdFromMultiTenant, setTenantIdFromMultiTenant } =
    useAppConfigurationStore((state) => ({
      tenantIdFromMultiTenant: state.tenantId,
      setTenantIdFromMultiTenant: state.setTenantId,
    }));

  // Token for get tenant settings first from url and then from auth context
  const tokenForGetTenantSettings = tokenFromUrl || token;

  // Get gcp tenant id from token if not in url or auth context
  const gcpTenantIdFromToken = tokenForGetTenantSettings
    ? getGcpTenantIdFromToken(tokenForGetTenantSettings)
    : undefined;

  // Get tenant settings from jwt token if available
  const appTenantSettingsFromJwtTokenQuery = useQuery({
    queryKey: [QueryKeys.GET_TENANT_SETTINGS, { gcpTenantIdFromToken }],
    queryFn: gcpTenantIdFromToken
      ? () =>
          api.tenants.getTenantSettings({
            tenantGcpId: gcpTenantIdFromToken,
          })
      : skipToken,
    retry: (failureCount) => {
      return failureCount <= 3;
    },
  });

  // Get tenant settings query from app settings if available
  const appTenantSettingsFromAppSettingsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_TENANT_SETTINGS,
      { tenantGcpId: appSettings.defaultTenantGcpId },
    ],
    queryFn: appSettings.defaultTenantGcpId
      ? () =>
          api.tenants.getTenantSettings({
            tenantGcpId: appSettings.defaultTenantGcpId,
          })
      : skipToken,
    retry: (failureCount) => {
      return failureCount <= 3;
    },
  });

  // Get tenant settings from multi tenant store
  const appTenantSettingsFromMultiTenantStoreQuery = useQuery({
    queryKey: [QueryKeys.GET_TENANT_SETTINGS, { tenantIdFromMultiTenant }],
    queryFn: tenantIdFromMultiTenant
      ? () =>
          api.tenants.getTenantSettings({
            tenantId: tenantIdFromMultiTenant,
          })
      : skipToken,
    retry: (failureCount) => {
      return failureCount <= 3;
    },
  });

  const resolvedTenantSettings = useMemo(() => {
    return (
      appTenantSettingsFromJwtTokenQuery.data ||
      appTenantSettingsFromAppSettingsQuery.data ||
      appTenantSettingsFromMultiTenantStoreQuery.data ||
      null
    );
  }, [
    appTenantSettingsFromJwtTokenQuery.data,
    appTenantSettingsFromAppSettingsQuery.data,
    appTenantSettingsFromMultiTenantStoreQuery.data,
  ]);

  const isLoading = useMemo(
    () =>
      appTenantSettingsFromJwtTokenQuery.isLoading ||
      appTenantSettingsFromAppSettingsQuery.isLoading ||
      appTenantSettingsFromMultiTenantStoreQuery.isLoading,
    [
      appTenantSettingsFromJwtTokenQuery.isLoading,
      appTenantSettingsFromAppSettingsQuery.isLoading,
      appTenantSettingsFromMultiTenantStoreQuery.isLoading,
    ],
  );

  // Set tenant id in auth context
  useEffect(() => {
    if (resolvedTenantSettings?.tenantGcpId) {
      setAuthTenantGcpId(resolvedTenantSettings.tenantGcpId);
      setTenantIdFromMultiTenant(resolvedTenantSettings.tenantId);
    }
  }, [setAuthTenantGcpId, resolvedTenantSettings, setTenantIdFromMultiTenant]);

  if (isLoading) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  return (
    <>
      {!resolvedTenantSettings && <TenantCodePage />}
      {resolvedTenantSettings && (
        <TenantSettingsProvider tenantSettings={resolvedTenantSettings}>
          <TenantPage>
            <Outlet />
          </TenantPage>
        </TenantSettingsProvider>
      )}
    </>
  );
};
