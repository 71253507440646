import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { TenantLogo } from 'components/TenantLogo';
import { FC, ReactNode } from 'react';
import { useAppMenusStore } from 'stores/appMenusStore';

export type GlobalViewHeaderProps = {
  children?: ReactNode;
};

export const GlobalViewHeader: FC<GlobalViewHeaderProps> = ({ children }) => {
  const { toggleDesktopSideBarMenu } = useAppMenusStore();

  return (
    <div className="grid grid-cols-[1fr_auto_1fr] w-full pb-4 items-center">
      <div className="block md:hidden min-w-12">
        <TenantLogo type="small" className="w-12" />
      </div>
      <div className="hidden md:block min-w-28">
        <TenantLogo type="default" className="w-28" />
      </div>
      <div>{children}</div>
      <button
        onClick={toggleDesktopSideBarMenu}
        className="text-right  shrink-0"
      >
        <FontIcon name="menu" />
      </button>
    </div>
  );
};
