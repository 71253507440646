import { Button } from '../Button';
import { FontIcon } from '../FontIcon';
import { InlineRecommendation } from '../InlineRecommendation';
import { Tooltip } from '../Tooltip';
import clsx from 'clsx';
import { forwardRef, ReactNode, useState } from 'react';

export type InputTextProps = {
  id: string;
  name: string;
  type: 'text' | 'email' | 'password' | 'number';
  label?: string;
  placeholder: string;
  value?: string;
  unit?: string;
  autoComplete?: string;
  hiddenLabel?: boolean;
  readOnly?: boolean;
  showPasswordToggleButton?: boolean;
  error?: string;
  helpTooltip?: ReactNode;
  recommendation?: ReactNode;
  footerNote?: ReactNode;
  withCopyButton?: boolean;
  onCopy?: (value: string) => void;
  step?: string | number;
  min?: string | number;
  max?: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const {
      id,
      name,
      type,
      label,
      hiddenLabel,
      readOnly,
      placeholder,
      value,
      unit,
      autoComplete,
      showPasswordToggleButton,
      error,
      helpTooltip,
      recommendation,
      footerNote,
      withCopyButton,
      onCopy,
      step,
      min,
      max,
      onChange,
      onClick,
      onFocus,
    } = props;

    const [isCopied, setIsCopied] = useState(false);
    const [valueToCopy, setValueToCopy] = useState(value);

    const handleCopyClick = () => {
      if (!valueToCopy) return;
      navigator.clipboard
        .writeText(valueToCopy)
        .then(() => {
          onCopy?.(valueToCopy);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    return (
      <div className="relative flex flex-col gap-1 w-full">
        {(label || helpTooltip) && (
          <div
            className={clsx(
              'flex items-center gap-1',
              withCopyButton ? 'justify-start' : 'justify-between',
            )}
          >
            {label && (
              <label
                htmlFor={name}
                hidden={hiddenLabel}
                className="text-clevergy-text font-semibold"
              >
                {label}
              </label>
            )}
            {helpTooltip && (
              <Tooltip content={helpTooltip}>
                <div className="text-clevergy-primary ">
                  <FontIcon name="info" size={24} variant="outlined" />
                </div>
              </Tooltip>
            )}
          </div>
        )}
        {recommendation && (
          <InlineRecommendation
            content={recommendation}
            icon={<FontIcon name="thumb_up" size={16} />}
          />
        )}

        <div className="flex items-center gap-2 w-full">
          <div className="relative grow">
            <input
              className={clsx(
                'font-normal text-clevergy-text placeholder-neutral-400 w-full rounded-lg border border-clevergy-border bg-white px-3 py-2 placeholder:font-normal focus:border-clevergy-primary focus:outline-none',
                {
                  'pr-12': unit,
                  'pr-10': showPasswordToggleButton,
                  '!bg-gray-100 !text-clevergy-subtext focus:!border-gray-400':
                    readOnly,
                },
              )}
              id={id}
              ref={ref}
              name={name}
              placeholder={placeholder}
              type={showPassword && type === 'password' ? 'text' : type}
              value={value}
              step={step}
              min={min}
              max={max}
              autoComplete={autoComplete}
              aria-invalid={Boolean(error)}
              readOnly={readOnly}
              onChange={(e) => {
                setValueToCopy(e.target.value);
                onChange?.(e);
              }}
              onClick={onClick}
              onFocus={onFocus}
            />
            {(type === 'text' || type === 'number') && unit && (
              <span className="absolute right-2 top-1/2 -translate-y-1/2 text-clevergy-subtext">
                {unit}
              </span>
            )}
            {type === 'password' && showPasswordToggleButton && (
              <button
                tabIndex={-1}
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-2 top-1/2 -translate-y-1/2 text-clevergy-subtext"
              >
                <FontIcon
                  name={showPassword ? 'visibility' : 'visibility_off'}
                  size={24}
                />
              </button>
            )}
            {readOnly && type !== 'password' && (
              <span className="absolute right-2 top-1/2 -translate-y-1/2 text-clevergy-subtext">
                <FontIcon name="lock" size={24} />
              </span>
            )}
          </div>
          {withCopyButton && (
            <Button
              onClick={handleCopyClick}
              variant="outlined"
              disabled={!valueToCopy || valueToCopy === ''}
            >
              <span>{isCopied ? 'Copiado!' : 'Copiar'}</span>
            </Button>
          )}
        </div>
        {error && <span className="text-red-800 block text-sm">{error}</span>}
        {footerNote && (
          <p className="text-sm text-clevergy-subtext">{footerNote}</p>
        )}
      </div>
    );
  },
);

InputText.displayName = 'InputText';
