/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  EnergyComparison,
  EnergyItem,
  HouseDataEnergy,
} from '../models/index';
import {
  EnergyComparisonFromJSON,
  EnergyComparisonToJSON,
  EnergyItemFromJSON,
  EnergyItemToJSON,
  HouseDataEnergyFromJSON,
  HouseDataEnergyToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface FindEnergyByHouseIdRequest {
  houseId: string;
  startDate: Date;
  endDate: Date;
  granularity: FindEnergyByHouseIdGranularityEnum;
  unit?: FindEnergyByHouseIdUnitEnum;
}

export interface FindEnergyByUserIdRequest {
  userId: string;
  startDate: Date;
  endDate: Date;
  granularity: FindEnergyByUserIdGranularityEnum;
  unit?: FindEnergyByUserIdUnitEnum;
}

export interface FindHouseSuppliesEnergyRequest {
  houseId: string;
  startDate: Date;
  endDate: Date;
  granularity: FindHouseSuppliesEnergyGranularityEnum;
}

export interface GetEnergyComparisonRequest {
  houseId: string;
  month: string;
}

/**
 *
 */
export class EnergyApi extends runtime.BaseAPI {
  /**
   * Returns the energy of a house for a date period (from startDate to endDate) grouped by date based on granularity
   */
  async findEnergyByHouseIdRaw(
    requestParameters: FindEnergyByHouseIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<EnergyItem>>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling findEnergyByHouseId().',
      );
    }

    if (requestParameters['startDate'] == null) {
      throw new runtime.RequiredError(
        'startDate',
        'Required parameter "startDate" was null or undefined when calling findEnergyByHouseId().',
      );
    }

    if (requestParameters['endDate'] == null) {
      throw new runtime.RequiredError(
        'endDate',
        'Required parameter "endDate" was null or undefined when calling findEnergyByHouseId().',
      );
    }

    if (requestParameters['granularity'] == null) {
      throw new runtime.RequiredError(
        'granularity',
        'Required parameter "granularity" was null or undefined when calling findEnergyByHouseId().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['startDate'] != null) {
      queryParameters['startDate'] = (
        requestParameters['startDate'] as any
      ).toISOString();
    }

    if (requestParameters['endDate'] != null) {
      queryParameters['endDate'] = (
        requestParameters['endDate'] as any
      ).toISOString();
    }

    if (requestParameters['granularity'] != null) {
      queryParameters['granularity'] = requestParameters['granularity'];
    }

    if (requestParameters['unit'] != null) {
      queryParameters['unit'] = requestParameters['unit'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/energy-chart`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(EnergyItemFromJSON),
    );
  }

  /**
   * Returns the energy of a house for a date period (from startDate to endDate) grouped by date based on granularity
   */
  async findEnergyByHouseId(
    requestParameters: FindEnergyByHouseIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<EnergyItem>> {
    const response = await this.findEnergyByHouseIdRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns the energy of many houses for a date period (from startDate to endDate) grouped by date based on granularity
   */
  async findEnergyByUserIdRaw(
    requestParameters: FindEnergyByUserIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<EnergyItem>>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling findEnergyByUserId().',
      );
    }

    if (requestParameters['startDate'] == null) {
      throw new runtime.RequiredError(
        'startDate',
        'Required parameter "startDate" was null or undefined when calling findEnergyByUserId().',
      );
    }

    if (requestParameters['endDate'] == null) {
      throw new runtime.RequiredError(
        'endDate',
        'Required parameter "endDate" was null or undefined when calling findEnergyByUserId().',
      );
    }

    if (requestParameters['granularity'] == null) {
      throw new runtime.RequiredError(
        'granularity',
        'Required parameter "granularity" was null or undefined when calling findEnergyByUserId().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['startDate'] != null) {
      queryParameters['startDate'] = (
        requestParameters['startDate'] as any
      ).toISOString();
    }

    if (requestParameters['endDate'] != null) {
      queryParameters['endDate'] = (
        requestParameters['endDate'] as any
      ).toISOString();
    }

    if (requestParameters['granularity'] != null) {
      queryParameters['granularity'] = requestParameters['granularity'];
    }

    if (requestParameters['unit'] != null) {
      queryParameters['unit'] = requestParameters['unit'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/users/{userId}/energy-chart`.replace(
          `{${'userId'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(EnergyItemFromJSON),
    );
  }

  /**
   * Returns the energy of many houses for a date period (from startDate to endDate) grouped by date based on granularity
   */
  async findEnergyByUserId(
    requestParameters: FindEnergyByUserIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<EnergyItem>> {
    const response = await this.findEnergyByUserIdRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns the energy and consumption of a house. The date range depends of the startDate & endDate params and the grouping depends of the granularity param.
   */
  async findHouseSuppliesEnergyRaw(
    requestParameters: FindHouseSuppliesEnergyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HouseDataEnergy>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling findHouseSuppliesEnergy().',
      );
    }

    if (requestParameters['startDate'] == null) {
      throw new runtime.RequiredError(
        'startDate',
        'Required parameter "startDate" was null or undefined when calling findHouseSuppliesEnergy().',
      );
    }

    if (requestParameters['endDate'] == null) {
      throw new runtime.RequiredError(
        'endDate',
        'Required parameter "endDate" was null or undefined when calling findHouseSuppliesEnergy().',
      );
    }

    if (requestParameters['granularity'] == null) {
      throw new runtime.RequiredError(
        'granularity',
        'Required parameter "granularity" was null or undefined when calling findHouseSuppliesEnergy().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['startDate'] != null) {
      queryParameters['startDate'] = (
        requestParameters['startDate'] as any
      ).toISOString();
    }

    if (requestParameters['endDate'] != null) {
      queryParameters['endDate'] = (
        requestParameters['endDate'] as any
      ).toISOString();
    }

    if (requestParameters['granularity'] != null) {
      queryParameters['granularity'] = requestParameters['granularity'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/consumption-chart`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HouseDataEnergyFromJSON(jsonValue),
    );
  }

  /**
   * Returns the energy and consumption of a house. The date range depends of the startDate & endDate params and the grouping depends of the granularity param.
   */
  async findHouseSuppliesEnergy(
    requestParameters: FindHouseSuppliesEnergyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HouseDataEnergy> {
    const response = await this.findHouseSuppliesEnergyRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Retrieves the energy comparison analysis of a house
   */
  async getEnergyComparisonRaw(
    requestParameters: GetEnergyComparisonRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EnergyComparison>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getEnergyComparison().',
      );
    }

    if (requestParameters['month'] == null) {
      throw new runtime.RequiredError(
        'month',
        'Required parameter "month" was null or undefined when calling getEnergyComparison().',
      );
    }

    const queryParameters: any = {};

    if (requestParameters['month'] != null) {
      queryParameters['month'] = requestParameters['month'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}/energy-comparison`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EnergyComparisonFromJSON(jsonValue),
    );
  }

  /**
   * Retrieves the energy comparison analysis of a house
   */
  async getEnergyComparison(
    requestParameters: GetEnergyComparisonRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EnergyComparison> {
    const response = await this.getEnergyComparisonRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}

/**
 * @export
 */
export const FindEnergyByHouseIdGranularityEnum = {
  Yearly: 'YEARLY',
  Monthly: 'MONTHLY',
  Daily: 'DAILY',
  Hourly: 'HOURLY',
} as const;
export type FindEnergyByHouseIdGranularityEnum =
  (typeof FindEnergyByHouseIdGranularityEnum)[keyof typeof FindEnergyByHouseIdGranularityEnum];
/**
 * @export
 */
export const FindEnergyByHouseIdUnitEnum = {
  Energy: 'ENERGY',
  Currency: 'CURRENCY',
} as const;
export type FindEnergyByHouseIdUnitEnum =
  (typeof FindEnergyByHouseIdUnitEnum)[keyof typeof FindEnergyByHouseIdUnitEnum];
/**
 * @export
 */
export const FindEnergyByUserIdGranularityEnum = {
  Yearly: 'YEARLY',
  Monthly: 'MONTHLY',
  Daily: 'DAILY',
  Hourly: 'HOURLY',
} as const;
export type FindEnergyByUserIdGranularityEnum =
  (typeof FindEnergyByUserIdGranularityEnum)[keyof typeof FindEnergyByUserIdGranularityEnum];
/**
 * @export
 */
export const FindEnergyByUserIdUnitEnum = {
  Energy: 'ENERGY',
  Currency: 'CURRENCY',
} as const;
export type FindEnergyByUserIdUnitEnum =
  (typeof FindEnergyByUserIdUnitEnum)[keyof typeof FindEnergyByUserIdUnitEnum];
/**
 * @export
 */
export const FindHouseSuppliesEnergyGranularityEnum = {
  Yearly: 'YEARLY',
  Monthly: 'MONTHLY',
  Daily: 'DAILY',
  Hourly: 'HOURLY',
} as const;
export type FindHouseSuppliesEnergyGranularityEnum =
  (typeof FindHouseSuppliesEnergyGranularityEnum)[keyof typeof FindHouseSuppliesEnergyGranularityEnum];
