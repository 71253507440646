import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type HouseKioskSettings = {
  title?: string;
  subtitle?: string;
};

export type HousesKioskStoreState = {
  housesKioskSettings: {
    [houseId: string]: HouseKioskSettings;
  };
};

export type HousesKioskStoreActions = {
  setKioskSettings: (houseId: string, settings: HouseKioskSettings) => void;
};

const initialState: HousesKioskStoreState = {
  housesKioskSettings: {},
};

export type HousesKioskStore = HousesKioskStoreState & HousesKioskStoreActions;

export const useHousesKioskStore = create<HousesKioskStore>()(
  persist(
    (set) => ({
      ...initialState,
      setKioskSettings: (houseId: string, settings: HouseKioskSettings) =>
        set((state) => ({
          housesKioskSettings: {
            ...state.housesKioskSettings,
            [houseId]: {
              title: settings.title,
              subtitle: settings.subtitle,
            },
          },
        })),
    }),
    {
      name: 'houseKioskStoreState',
    },
  ),
);
