/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  CreateHouse,
  CreateSolarAmortization,
  EquipmentsList,
  House,
  HttpError,
  SolarAmortization,
  SolarSavings,
  UpdateHouse,
} from '../models/index';
import {
  CreateHouseFromJSON,
  CreateHouseToJSON,
  CreateSolarAmortizationFromJSON,
  CreateSolarAmortizationToJSON,
  EquipmentsListFromJSON,
  EquipmentsListToJSON,
  HouseFromJSON,
  HouseToJSON,
  HttpErrorFromJSON,
  HttpErrorToJSON,
  SolarAmortizationFromJSON,
  SolarAmortizationToJSON,
  SolarSavingsFromJSON,
  SolarSavingsToJSON,
  UpdateHouseFromJSON,
  UpdateHouseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface CreateHouseRequest {
  house?: CreateHouse;
}

export interface CreateSolarAmortizationRequest {
  houseId: string;
  createSolarAmortization: CreateSolarAmortization;
}

export interface GetEquipmentsByHouseRequest {
  houseId: string;
}

export interface GetSolarAmortizationRequest {
  houseId: string;
}

export interface GetSolarSavingsRequest {
  houseId: string;
}

export interface UpdateHouseRequest {
  houseId: string;
  house?: UpdateHouse;
}

/**
 *
 */
export class HousesApi extends runtime.BaseAPI {
  /**
   * Creates a house associated to the request token user.
   */
  async createHouseRaw(
    requestParameters: CreateHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<House>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CreateHouseToJSON(requestParameters['house']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HouseFromJSON(jsonValue),
    );
  }

  /**
   * Creates a house associated to the request token user.
   */
  async createHouse(
    requestParameters: CreateHouseRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<House> {
    const response = await this.createHouseRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Creates solar amortization for a house. It also deletes the existing one and associated history.
   */
  async createSolarAmortizationRaw(
    requestParameters: CreateSolarAmortizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling createSolarAmortization().',
      );
    }

    if (requestParameters['createSolarAmortization'] == null) {
      throw new runtime.RequiredError(
        'createSolarAmortization',
        'Required parameter "createSolarAmortization" was null or undefined when calling createSolarAmortization().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/houses/{houseId}/solar-amortization`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CreateSolarAmortizationToJSON(
          requestParameters['createSolarAmortization'],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates solar amortization for a house. It also deletes the existing one and associated history.
   */
  async createSolarAmortization(
    requestParameters: CreateSolarAmortizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createSolarAmortizationRaw(requestParameters, initOverrides);
  }

  /**
   * Returns equipments for a house
   */
  async getEquipmentsByHouseRaw(
    requestParameters: GetEquipmentsByHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<EquipmentsList>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getEquipmentsByHouse().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/houses/{houseId}/equipments`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EquipmentsListFromJSON(jsonValue),
    );
  }

  /**
   * Returns equipments for a house
   */
  async getEquipmentsByHouse(
    requestParameters: GetEquipmentsByHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<EquipmentsList> {
    const response = await this.getEquipmentsByHouseRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns solar amortization for a house
   */
  async getSolarAmortizationRaw(
    requestParameters: GetSolarAmortizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SolarAmortization>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getSolarAmortization().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/houses/{houseId}/solar-amortization`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SolarAmortizationFromJSON(jsonValue),
    );
  }

  /**
   * Returns solar amortization for a house
   */
  async getSolarAmortization(
    requestParameters: GetSolarAmortizationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SolarAmortization> {
    const response = await this.getSolarAmortizationRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Returns solar savings for a house
   */
  async getSolarSavingsRaw(
    requestParameters: GetSolarSavingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<SolarSavings>>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling getSolarSavings().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/app/houses/{houseId}/solar-savings`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SolarSavingsFromJSON),
    );
  }

  /**
   * Returns solar savings for a house
   */
  async getSolarSavings(
    requestParameters: GetSolarSavingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<SolarSavings>> {
    const response = await this.getSolarSavingsRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Updates a house.
   */
  async updateHouseRaw(
    requestParameters: UpdateHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['houseId'] == null) {
      throw new runtime.RequiredError(
        'houseId',
        'Required parameter "houseId" was null or undefined when calling updateHouse().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'firebase',
        [],
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'connect-firebase',
        [],
      );
    }

    const response = await this.request(
      {
        path: `/houses/{houseId}`.replace(
          `{${'houseId'}}`,
          encodeURIComponent(String(requestParameters['houseId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateHouseToJSON(requestParameters['house']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates a house.
   */
  async updateHouse(
    requestParameters: UpdateHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.updateHouseRaw(requestParameters, initOverrides);
  }
}
