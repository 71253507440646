import { FC, ReactNode } from 'react';

export type InlineRecommendationProps = {
  icon?: ReactNode;
  content: ReactNode;
};

export const InlineRecommendation: FC<InlineRecommendationProps> = (props) => {
  const { icon, content } = props;

  return (
    <div className="flex items-start gap-2 text-clevergy-primary bg-clevergy-primary/10 rounded-xl py-2 px-4">
      {icon && <div className="py-1">{icon}</div>}
      <p>{content}</p>
    </div>
  );
};
