/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateSolarAmortization
 */
export interface CreateSolarAmortization {
  /**
   *
   * @type {Date}
   * @memberof CreateSolarAmortization
   */
  installationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof CreateSolarAmortization
   */
  initialCost?: number;
  /**
   *
   * @type {number}
   * @memberof CreateSolarAmortization
   */
  baseSavings?: number;
  /**
   *
   * @type {number}
   * @memberof CreateSolarAmortization
   */
  grantReceived?: number;
}

/**
 * Check if a given object implements the CreateSolarAmortization interface.
 */
export function instanceOfCreateSolarAmortization(
  value: object,
): value is CreateSolarAmortization {
  return true;
}

export function CreateSolarAmortizationFromJSON(
  json: any,
): CreateSolarAmortization {
  return CreateSolarAmortizationFromJSONTyped(json, false);
}

export function CreateSolarAmortizationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateSolarAmortization {
  if (json == null) {
    return json;
  }
  return {
    installationDate:
      json['installationDate'] == null
        ? undefined
        : new Date(json['installationDate']),
    initialCost: json['initialCost'] == null ? undefined : json['initialCost'],
    baseSavings: json['baseSavings'] == null ? undefined : json['baseSavings'],
    grantReceived:
      json['grantReceived'] == null ? undefined : json['grantReceived'],
  };
}

export function CreateSolarAmortizationToJSON(
  json: any,
): CreateSolarAmortization {
  return CreateSolarAmortizationToJSONTyped(json, false);
}

export function CreateSolarAmortizationToJSONTyped(
  value?: CreateSolarAmortization | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    installationDate:
      value['installationDate'] == null
        ? undefined
        : value['installationDate'].toISOString(),
    initialCost: value['initialCost'],
    baseSavings: value['baseSavings'],
    grantReceived: value['grantReceived'],
  };
}
