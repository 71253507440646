import { FindEnergyByUserIdGranularityEnum } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { DateRangeType } from '@clevergy/shared/types';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { endOfMonth, format, startOfMonth } from 'date-fns';

type UserSummaryProps = {
  dateRangeCurrentDate: Date;
};

export const UserSummary = (props: UserSummaryProps) => {
  const { dateRangeCurrentDate } = props;

  const { api } = useApiContext();
  const { authedUser, token } = useAuthContext();

  const startDate = startOfMonth(dateRangeCurrentDate || new Date());
  const endDate = endOfMonth(dateRangeCurrentDate || new Date());

  const energyByUserIdQuery = useQuery({
    queryKey: [
      QueryKeys.FIND_HOUSE_ENERGY_BY_USER_ID,
      {
        userId: authedUser?.uid,
        startDate,
        endDate,
        granularity: FindEnergyByUserIdGranularityEnum.Daily,
      },
    ],
    queryFn: authedUser?.uid
      ? () =>
          api.energy.findEnergyByUserId({
            userId: authedUser?.uid,
            startDate,
            endDate,
            granularity: FindEnergyByUserIdGranularityEnum.Daily,
          })
      : skipToken,
    placeholderData: (prev) => prev,
  });

  if (!authedUser) {
    return null;
  }

  const totalProduction = energyByUserIdQuery.data?.reduce(
    (acc, curr) => acc + (curr.solar?.production || 0),
    0,
  );

  if (!token) {
    return null;
  }

  return (
    <>
      {totalProduction !== undefined && totalProduction > 0 && (
        <clevergy-solar-summary
          data-token={token}
          data-show-dates-selector="false"
          data-date-range-type={DateRangeType.Month}
          data-date-range-current-date={format(
            dateRangeCurrentDate,
            'yyyy-MM-dd',
          )}
        />
      )}
      <clevergy-energy-chart
        data-token={token}
        data-show-dates-selector="false"
        data-date-range-type={DateRangeType.Month}
        data-date-range-current-date={format(
          dateRangeCurrentDate,
          'yyyy-MM-dd',
        )}
      />
    </>
  );
};
