import idaeLogo from '../assets/idae.png';
import ecoMinistryLogo from '../assets/ministry-eco-transition.png';
import nextGenerationEULogo from '../assets/next-generation-eu.png';
import resiliencePlanLogo from '../assets/resilience-plan.png';
import selfConsumptionLogo from '../assets/self-consumption.png';

export const EntitiesLogos = () => {
  return (
    <div className="flex items-center justify-center gap-4 flex-wrap mt-16">
      <img
        className="h-14 mr-36"
        src={nextGenerationEULogo}
        alt="Next Generation EU"
      />
      <img
        className="h-14"
        src={ecoMinistryLogo}
        alt="Ministerio para la transición ecológica y el reto demográfico"
      />
      <img className="h-14" src={idaeLogo} alt="IDAE" />
      <img
        className="h-14"
        src={resiliencePlanLogo}
        alt="Plan de Recuperación, Transformación y Resiliencia"
      />
      <img className="h-14" src={selfConsumptionLogo} alt="Autoconsumo" />
    </div>
  );
};
