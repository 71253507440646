import { create } from 'zustand';

export type AppMenusStoreState = {
  isDesktopSideBarMenuOpen: boolean;
};

export type AppMenusStoreActions = {
  setIsDesktopSideBarMenuOpen: (newState: boolean) => void;
  toggleDesktopSideBarMenu: () => void;
};

const initialState: AppMenusStoreState = {
  isDesktopSideBarMenuOpen: false,
};

export type AppMenusStore = AppMenusStoreState & AppMenusStoreActions;

export const useAppMenusStore = create<AppMenusStore>()((set) => ({
  ...initialState,
  setIsDesktopSideBarMenuOpen: (newState: boolean) => {
    set({ isDesktopSideBarMenuOpen: newState });
  },
  toggleDesktopSideBarMenu: () =>
    set((state) => ({
      isDesktopSideBarMenuOpen: !state.isDesktopSideBarMenuOpen,
    })),
}));
