import { Button } from '@clevergy/ui/components/Button';
import { InputText } from '@clevergy/ui/components/InputText';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHousesKioskStore } from 'stores/houseKioskStore';

type BoardSettingsForm = {
  title: string;
  subtitle: string;
};

export type BoardSettingsProps = {
  onDone: () => void;
};

export const BoardSettings: FC<BoardSettingsProps> = (props) => {
  const { onDone } = props;
  const { selectedHouse } = useSelectedHouse();
  const { housesKioskSettings, setKioskSettings } = useHousesKioskStore();
  const { t } = useTranslation();

  const kioskSettings = useMemo(() => {
    if (!selectedHouse) {
      return undefined;
    }
    const kioskSettings = housesKioskSettings[selectedHouse?.details.houseId];
    return kioskSettings;
  }, [housesKioskSettings, selectedHouse]);

  const { register, formState, handleSubmit } = useForm<BoardSettingsForm>({
    defaultValues: {
      title: kioskSettings?.title,
      subtitle: kioskSettings?.subtitle,
    },
  });

  if (!selectedHouse) {
    return null;
  }

  const onSubmit = (data: BoardSettingsForm) => {
    setKioskSettings(selectedHouse?.details.houseId, {
      title: data.title,
      subtitle: data.subtitle,
    });
    onDone();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div>
          <Trans
            t={t}
            values={{
              houseName: selectedHouse.details.address,
            }}
          >
            kioskMode.boardSettings.form.intro
          </Trans>
        </div>
        <div>
          <p className="font-semibold">
            {t('kioskMode.boardSettings.form.logoInstitutional.label')}
          </p>
          <p>
            {t('kioskMode.boardSettings.form.logoInstitutional.textPart1')}
            <Link
              className="text-clevergy-primary underline"
              to={`/support/tickets/create?subject=${t(
                'kioskMode.boardSettings.form.logoInstitutional.supportSubject',
              )}`}
            >
              {t('kioskMode.boardSettings.form.logoInstitutional.textPart2')}
            </Link>
            {t('kioskMode.boardSettings.form.logoInstitutional.textPart3')}
          </p>
        </div>
      </div>
      <InputText
        id="title"
        label={t('kioskMode.boardSettings.form.title.label')}
        type="text"
        placeholder={t('kioskMode.boardSettings.form.title.placeholder')}
        {...register('title', {
          required: t('kioskMode.boardSettings.form.title.required'),
          onChange(event) {
            setKioskSettings(selectedHouse?.details.houseId, {
              ...kioskSettings,
              title: event.target.value,
            });
          },
        })}
        value={kioskSettings?.title}
        error={formState.errors.title?.message}
      />

      <InputText
        id="subtitle"
        label={t('kioskMode.boardSettings.form.subtitle.label')}
        type="text"
        placeholder={t('kioskMode.boardSettings.form.subtitle.placeholder')}
        {...register('subtitle', {
          required: t('kioskMode.boardSettings.form.subtitle.required'),
          onChange(event) {
            setKioskSettings(selectedHouse?.details.houseId, {
              ...kioskSettings,
              subtitle: event.target.value,
            });
          },
        })}
        value={kioskSettings?.subtitle}
        error={formState.errors.subtitle?.message}
      />

      <Button type="submit" variant="filled" fullWidth>
        {t('kioskMode.boardSettings.form.openKioskModeButton')}
      </Button>
    </form>
  );
};
