import { FontIcon } from '@clevergy/ui/components/FontIcon';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import {
  NavigationMenuItemDescription,
  useNavigationMenu,
} from 'hooks/useNavigationMenu';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link, matchRoutes, useLocation } from 'react-router-dom';

export const NonMobileNavbarMenu = () => {
  const { navigationMenuItems } = useNavigationMenu();

  return (
    <div className="flex flex-col">
      {navigationMenuItems.map((item, index) => {
        if (item === 'separator') {
          return (
            <div key={`separator-${index}`} className="h-px my-5 bg-gray-200" />
          );
        }
        return (
          <NonMobileNavbarMenuItem key={item.label} itemDescription={item} />
        );
      })}
    </div>
  );
};

const NonMobileNavbarMenuItem = (props: {
  itemDescription: NavigationMenuItemDescription;
  ignoreIcon?: boolean;
}) => {
  const { itemDescription, ignoreIcon = false } = props;

  if (
    itemDescription.childrenItems &&
    itemDescription.childrenItems.length > 0
  ) {
    return (
      <NonMobileNavbarCollapsableGroup
        label={itemDescription.label}
        icon={itemDescription.icon}
        childrenItems={itemDescription.childrenItems}
      />
    );
  }

  if (itemDescription.to && itemDescription.renderCondition) {
    return (
      <NavbarItemLink
        label={itemDescription.label}
        icon={ignoreIcon ? null : itemDescription.icon}
        to={itemDescription.to}
        badge={itemDescription.badge}
        matchingRoutes={itemDescription.matchingRoutes || []}
      />
    );
  }

  return null;
};

export const NonMobileNavbarCollapsableGroup = (props: {
  icon: ReactNode;
  label: string;
  childrenItems: NavigationMenuItemDescription[];
}) => {
  const { icon, label, childrenItems } = props;

  const location = useLocation();
  const userHasToggled = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  const matchingRoutes = childrenItems?.flatMap((i) => i.matchingRoutes || []);
  const isActive = matchRoutes(
    matchingRoutes.map((route) => ({ path: route })),
    location,
  );

  useEffect(() => {
    if (location) {
      userHasToggled.current = false;
    }
  }, [location]);

  useEffect(() => {
    if (isActive && !userHasToggled.current) {
      setIsOpen(true);
    }
  }, [isActive]);

  const handleToggle = () => {
    userHasToggled.current = true;
    setIsOpen(!isOpen);
  };

  if (!childrenItems.some((i) => i.renderCondition)) {
    return null;
  }

  return (
    <div>
      <button
        type="button"
        className={clsx(
          'flex items-center gap-1 px-4 py-3 w-full hover:bg-gray-100 transition-colors',
        )}
        onClick={handleToggle}
      >
        <div className="w-6">{icon}</div>
        <div className="flex gap-1">
          {label}
          <div className="w-6">
            <motion.div
              animate={{ rotate: isOpen ? -180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <FontIcon name="expand_more" />
            </motion.div>
          </div>
        </div>
      </button>
      <motion.div
        style={{ overflow: 'hidden' }}
        initial={{ height: 0, opacity: 1 }}
        animate={{
          height: isOpen ? 'auto' : 0,
          transition: { type: 'tween' },
        }}
        exit={{ height: 0, opacity: 1 }}
      >
        {childrenItems.map((itemDescription, index) => {
          return (
            <NonMobileNavbarMenuItem
              key={index}
              itemDescription={itemDescription}
              ignoreIcon={true}
            />
          );
        })}
      </motion.div>
    </div>
  );
};

const NavbarItemLink = (props: {
  to: string;
  label: string;
  icon: ReactNode;
  badge?: number;
  matchingRoutes: string[];
}) => {
  const { to, label, icon, matchingRoutes, badge = 0 } = props;
  const location = useLocation();
  const isActive = matchRoutes(
    matchingRoutes.map((route) => ({ path: route })),
    location,
  );

  return (
    <AnimatePresence key={to} mode="popLayout">
      <Link to={to}>
        <div
          className={clsx(
            'flex items-center gap-1 px-4 py-3 w-full hover:bg-gray-100 transition-colors',
            {
              'text-clevergy-primary': isActive,
            },
          )}
        >
          <div className="w-6">{icon}</div>
          <div>{label}</div>
          {badge > 0 && (
            <motion.div
              className="flex items-center justify-center bg-clevergy-secondary text-white font-semibold rounded-full text-[10px] w-4 h-4 ml-1"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ scale: 0 }}
            >
              {badge}
            </motion.div>
          )}
        </div>
      </Link>
    </AnimatePresence>
  );
};
