/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface CreateB2CIntegrationRequest
 */
export interface CreateB2CIntegrationRequest {
  /**
   *
   * @type {string}
   * @memberof CreateB2CIntegrationRequest
   */
  vendor: CreateB2CIntegrationRequestVendorEnum;
  /**
   *
   * @type {string}
   * @memberof CreateB2CIntegrationRequest
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof CreateB2CIntegrationRequest
   */
  password: string;
}

/**
 * @export
 */
export const CreateB2CIntegrationRequestVendorEnum = {
  FroniusB2C: 'FRONIUS_B2C',
} as const;
export type CreateB2CIntegrationRequestVendorEnum =
  (typeof CreateB2CIntegrationRequestVendorEnum)[keyof typeof CreateB2CIntegrationRequestVendorEnum];

/**
 * Check if a given object implements the CreateB2CIntegrationRequest interface.
 */
export function instanceOfCreateB2CIntegrationRequest(
  value: object,
): value is CreateB2CIntegrationRequest {
  if (!('vendor' in value) || value['vendor'] === undefined) return false;
  if (!('user' in value) || value['user'] === undefined) return false;
  if (!('password' in value) || value['password'] === undefined) return false;
  return true;
}

export function CreateB2CIntegrationRequestFromJSON(
  json: any,
): CreateB2CIntegrationRequest {
  return CreateB2CIntegrationRequestFromJSONTyped(json, false);
}

export function CreateB2CIntegrationRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateB2CIntegrationRequest {
  if (json == null) {
    return json;
  }
  return {
    vendor: json['vendor'],
    user: json['user'],
    password: json['password'],
  };
}

export function CreateB2CIntegrationRequestToJSON(
  json: any,
): CreateB2CIntegrationRequest {
  return CreateB2CIntegrationRequestToJSONTyped(json, false);
}

export function CreateB2CIntegrationRequestToJSONTyped(
  value?: CreateB2CIntegrationRequest | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    vendor: value['vendor'],
    user: value['user'],
    password: value['password'],
  };
}
