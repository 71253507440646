import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState: ExternalOauthProxyState = {
  jwt: null,
  callbackUrl: null,
  houseId: null,
};

export type ExternalOauthProxyState = {
  jwt: string | null | undefined;
  callbackUrl: string | null | undefined;
  houseId: string | null | undefined;
};

export type ExternalOauthProxyActions = {
  setJwt: (jwt: string) => void;
  setCallbackUrl: (callbackUrl: string) => void;
  setHouseId: (houseId: string) => void;
  reset: () => void;
};

export type ExternalOauthProxyStore = ExternalOauthProxyState &
  ExternalOauthProxyActions;

export const useExternalOauthProxyStore = create<ExternalOauthProxyStore>()(
  persist(
    (set) => ({
      ...initialState,
      setJwt: (jwt: string) => set({ jwt }),
      setCallbackUrl: (callbackUrl: string) => set({ callbackUrl }),
      setHouseId: (houseId: string) => set({ houseId }),
      reset: () =>
        set({
          jwt: undefined,
          callbackUrl: undefined,
          houseId: undefined,
        }),
    }),
    {
      name: 'externalOauthProxyStore',
      version: 1,
    },
  ),
);
