import { FontIcon } from '../FontIcon';
import clsx from 'clsx';
import { forwardRef } from 'react';

export type InputSelectProps = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
  selectedValueId: string | undefined;
  values: Array<{ id: string; text: string }>;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: string;
  description?: string;
  hiddenLabel?: boolean;
  flatMode?: boolean;
  fullWidth?: boolean;
};

export const InputSelect = forwardRef<HTMLSelectElement, InputSelectProps>(
  (props, ref) => {
    const {
      id,
      name,
      label,
      placeholder,
      selectedValueId,
      values,
      error,
      description,
      onChange,
      flatMode = false,
      hiddenLabel = false,
      fullWidth = false,
    } = props;

    const selectedValueText =
      values.find((value) => value.id === selectedValueId)?.text || '';

    return (
      <div
        className={clsx('relative flex flex-col gap-1 max-w-full', {
          'w-min': !fullWidth,
        })}
        data-flat-mode={flatMode}
      >
        <label
          htmlFor={name}
          hidden={hiddenLabel}
          className="text-clevergy-text font-semibold"
        >
          {label}
        </label>
        <div
          className={clsx(
            'relative flex w-full items-center gap-1 border-clevergy-border rounded-md border p-2',
            {
              'border-none px-0': flatMode,
            },
          )}
        >
          <div className="overflow-hidden text-ellipsis whitespace-nowrap flex-grow text-clevergy-text h-6 leading-[24px]">
            {selectedValueText && <>{selectedValueText}</>}
            {!selectedValueText && (
              <span className="text-clevergy-unselected">{placeholder}</span>
            )}
          </div>
          <select
            id={id}
            ref={ref}
            name={name}
            value={selectedValueId}
            aria-hidden={values.length === 1 && Boolean(selectedValueId)}
            className="absolute inset-0 h-full w-full overflow-hidden text-lg appearance-none bg-transparent text-transparent aria-hidden:hidden"
            onChange={onChange}
          >
            <option hidden></option>
            {values.map(({ text, id }) => (
              <option key={id} value={id}>
                {text}
              </option>
            ))}
          </select>
          {(values.length > 1 || !selectedValueId) && (
            <div className="text-clevergy-text flex-grow-0">
              <FontIcon name="expand_more" />
            </div>
          )}
        </div>
        {error && <span className="text-red-800 block text-sm">{error}</span>}
        {description && (
          <div className="flex gap-1 mt-1 text-clevergy-unselected">
            <span className="block mt-[2px]">
              <FontIcon name="info" variant="outlined" size={15} />
            </span>
            <span className="block text-sm">{description}</span>
          </div>
        )}
      </div>
    );
  },
);

InputSelect.displayName = 'InputSelect';
