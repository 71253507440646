import { FindEnergyByUserIdGranularityEnum } from '@clevergy/api-client';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { formatNumber } from '@clevergy/utils/format/formatNumber';
import { skipToken } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useAuthContext } from 'context/AuthContext';
import { startOfMonth } from 'date-fns';
import { endOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';

export type UserHousesTotalsProps = {
  dateRangeCurrentDate: Date;
};

export const UserHousesTotals = (props: UserHousesTotalsProps) => {
  const { dateRangeCurrentDate } = props;
  const { api } = useApiContext();
  const { authedUser } = useAuthContext();
  const { t } = useTranslation();

  const startDate = startOfMonth(dateRangeCurrentDate || new Date());
  const endDate = endOfMonth(dateRangeCurrentDate || new Date());

  const energyByUserIdQuery = useQuery({
    queryKey: [
      QueryKeys.FIND_HOUSE_ENERGY_BY_USER_ID,
      {
        userId: authedUser?.uid,
        startDate,
        endDate,
        granularity: FindEnergyByUserIdGranularityEnum.Daily,
      },
    ],
    queryFn: authedUser?.uid
      ? () =>
          api.energy.findEnergyByUserId({
            userId: authedUser?.uid,
            startDate,
            endDate,
            granularity: FindEnergyByUserIdGranularityEnum.Daily,
          })
      : skipToken,
    placeholderData: (prev) => prev,
  });

  if (energyByUserIdQuery.isPending) {
    return <LoadingIndicator variant="spinner" centered />;
  }

  if (energyByUserIdQuery.isError) {
    return null;
  }

  const totalConsumption = energyByUserIdQuery.data?.reduce(
    (acc, curr) => acc + (curr.house?.consumption || 0),
    0,
  );

  const totalProduction = energyByUserIdQuery.data?.reduce(
    (acc, curr) => acc + (curr.solar?.production || 0),
    0,
  );

  return (
    <div className="flex gap-6 font-semibold">
      {totalConsumption !== undefined && totalConsumption > 0 && (
        <div className="flex flex-col md:w-72">
          <div className="text-sm md:text-lg">
            {t('houses.totalConsumption')}
          </div>
          <div className="flex gap-2 items-baseline">
            <span className="text-2xl md:text-4xl">
              {
                formatNumber({
                  value: totalConsumption,
                  precision: 2,
                  language: 'es-ES',
                }).formattedValue
              }
            </span>
            <span className="text-sm md:text-lg">kWh</span>
          </div>
        </div>
      )}
      {totalProduction !== undefined && totalProduction > 0 && (
        <div className="flex flex-col md:w-72">
          <div className="text-sm md:text-lg">
            {t('houses.totalProduction')}
          </div>
          <div className="flex gap-2 items-baseline">
            <span className="text-2xl md:text-4xl">
              {
                formatNumber({
                  value: totalProduction,
                  precision: 2,
                  language: 'es-ES',
                }).formattedValue
              }
            </span>
            <span className="text-sm md:text-lg">kWh</span>
          </div>
        </div>
      )}
    </div>
  );
};
