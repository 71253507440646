import { tenantsAssetsBaseUrl } from '@clevergy/shared/constants/default';
import { injectCssVariables } from '@clevergy/utils/browser/injectCssVariables';
import Color from 'color';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { FC, ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';

export type TenantPageProps = {
  children: ReactNode;
};

export const TenantPage: FC<TenantPageProps> = ({ children }) => {
  const tenantSettings = useTenantSettings();

  useEffect(() => {
    // turn object keys and values to dashed case and prepend with --
    const variables = Object.entries(
      tenantSettings.themeCssVariables ?? {},
    ).reduce(
      (acc, [key, value]) => {
        const varName = key.replace(/([A-Z])/g, '-$1').toLowerCase();
        acc[`--${varName}`] = value;
        return acc;
      },
      {} as Record<string, string>,
    );
    // calculate the fade variants of the primary color
    [10, 20, 30, 40, 50, 60, 70, 80, 90].forEach((fade) => {
      if (tenantSettings.themeCssVariables?.clevergyColorPrimary) {
        variables[`--clevergy-calculated-color-primary-opacity-${fade}`] =
          Color(tenantSettings.themeCssVariables.clevergyColorPrimary)
            .fade(1 - fade / 100)
            .rgb()
            .toString();
      }
      if (tenantSettings.themeCssVariables?.clevergyColorSecondary) {
        variables[`--clevergy-calculated-color-secondary-opacity-${fade}`] =
          Color(tenantSettings.themeCssVariables.clevergyColorSecondary)
            .fade(1 - fade / 100)
            .rgb()
            .toString();
      }
    });

    injectCssVariables(variables);
  }, [tenantSettings.themeCssVariables]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'es' }}>
        <title>{tenantSettings.name}</title>
        <link
          rel="icon"
          href={`${tenantsAssetsBaseUrl}/${tenantSettings.tenantId}/favicon.png`}
        />
        {tenantSettings.themeFontFaceUrl && (
          <link rel="stylesheet" href={tenantSettings.themeFontFaceUrl} />
        )}
      </Helmet>
      {children}
    </>
  );
};
