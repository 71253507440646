import { useApiContext } from './ApiContext';
import { ConsumerAppSettings } from '@clevergy/api-client-portal-routes';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { useQuery } from '@tanstack/react-query';
import { FC, ReactNode, createContext, useContext } from 'react';

export type AppSettingsContextValue = {
  appSettings: ConsumerAppSettings;
};

export const AppSettingsContext = createContext<AppSettingsContextValue | null>(
  null,
);

export type AppSettingsProviderProps = {
  children: ReactNode;
};

export const AppSettingsProvider: FC<AppSettingsProviderProps> = ({
  children,
}) => {
  const { api } = useApiContext();

  const appInfoQuery = useQuery({
    queryKey: [QueryKeys.GET_APP_INFO, { domain: window.location.hostname }],
    queryFn: () =>
      api.apps.getAppSettings({ domain: window.location.hostname }),
    retry: (failureCount) => {
      return failureCount <= 3;
    },
  });

  if (appInfoQuery.isPending) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  if (appInfoQuery.isError) {
    throw new Error('Error loading app settings');
  }

  return (
    <AppSettingsContext.Provider value={{ appSettings: appInfoQuery.data }}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => {
  const setup = useContext(AppSettingsContext);
  if (!setup) {
    throw new Error(`useAppSettings must be used within a AppSettingsProvider`);
  }
  return setup;
};
