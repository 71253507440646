import { IntegrationId, useIntegrations } from './useIntegrations';
import { useSelectedHouse } from './useSelectedHouse';
import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Icon } from '@clevergy/ui/components/Icon';
import { skipToken, useQuery } from '@tanstack/react-query';
import { useApiContext } from 'context/ApiContext';
import { useTenantSettings } from 'context/TenantSettingsContext';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import screenfull from 'screenfull';

export type NavigationMenuItemDescription = {
  label: string;
  icon: ReactNode;
  badge?: number;
  to?: string;
  renderCondition?: boolean;
  showAsTabInMobile: boolean;
  matchingRoutes?: string[];
  childrenItems?: NavigationMenuItemDescription[];
};

export type NavigationMenu = Array<NavigationMenuItemDescription | 'separator'>;

export const useNavigationMenu = (options?: {
  ignoreSelectedHouse?: boolean;
}) => {
  const { ignoreSelectedHouse = false } = options || {};
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { api } = useApiContext();
  const { selectedHouse } = useSelectedHouse();
  const { tenantId } = useTenantSettings();
  const { integrationsList } = useIntegrations();

  const shellyIntegration = integrationsList.find(
    (integration) => integration.id === IntegrationId.Shelly,
  );

  // Query to know if there is any recommendation to show badge
  const recommendationsQuery = useQuery({
    queryKey: [
      QueryKeys.GET_OPTIMIZE_RECOMMENDATIONS,
      {
        houseId: selectedHouse?.details.houseId,
      },
    ],
    queryFn: selectedHouse?.details.houseId
      ? () =>
          api.optimize.getOptimizeRecommendations({
            houseId: selectedHouse.details.houseId,
            locale: language,
          })
      : skipToken,
  });

  const optimizeBadge = recommendationsQuery.data?.length || 0;

  const hasSelectedHouse = !ignoreSelectedHouse && Boolean(selectedHouse);

  const showInvoicesTabInMobile =
    tenantId === '' ||
    tenantId === 'comunidad-solar' ||
    tenantId === 'eresenergia' ||
    tenantId === 'niba';

  const navigationMenuItems: NavigationMenu = [
    {
      icon: <FontIcon name="auto_awesome_mosaic" />,
      label: t('navbar.summary'),
      to: selectedHouse ? `/houses/${selectedHouse.details.houseId}` : '/',
      matchingRoutes: ['/', '/houses/:houseId'],
      renderCondition: !ignoreSelectedHouse,
      showAsTabInMobile: true,
    },
    {
      icon: <FontIcon name="grouped_bar_chart" />,
      label: t('navbar.analysis'),
      to: selectedHouse
        ? `/houses/${selectedHouse.details.houseId}/analysis`
        : '/analysis',
      matchingRoutes: ['/analysis', '/houses/:houseId/analysis'],
      renderCondition: !ignoreSelectedHouse,
      showAsTabInMobile: true,
    },
    {
      icon: <FontIcon name="contract" />,
      label: t('navbar.contract'),
      to: selectedHouse
        ? `/houses/${selectedHouse.details.houseId}/contract`
        : '/contract',
      matchingRoutes: ['/contract', '/houses/:houseId/contract'],
      renderCondition: hasSelectedHouse,
      showAsTabInMobile: false,
    },
    {
      icon: <FontIcon name="article" />,
      label: t('navbar.invoices'),
      to: selectedHouse
        ? `/houses/${selectedHouse.details.houseId}/invoices`
        : '/invoices',
      matchingRoutes: ['/invoices', '/houses/:houseId/invoices'],
      renderCondition: hasSelectedHouse,
      showAsTabInMobile: showInvoicesTabInMobile,
    },
    {
      icon: <FontIcon name="emoji_objects" />,
      label: t('navbar.optimize'),
      to: selectedHouse
        ? `/houses/${selectedHouse.details.houseId}/optimize`
        : '/optimize',
      badge: optimizeBadge,
      matchingRoutes: ['/optimize', '/houses/:houseId/optimize'],
      renderCondition: !ignoreSelectedHouse,
      showAsTabInMobile: true,
    },
    {
      icon: <FontIcon name="menu" />,
      label: t('navbar.more'),
      to: '/more',
      // Matching routes in group (with children items) only applied to mobile tabs
      matchingRoutes: [
        '/more',
        '/contract',
        '/houses/:houseId/contract',
        '/integrations/*',
        '/support/*',
        '/contact',
        ...(!showInvoicesTabInMobile
          ? ['/invoices', '/houses/:houseId/invoices']
          : []),
      ],
      showAsTabInMobile: true,
      childrenItems: [
        {
          icon: <FontIcon name="account_circle" />,
          label: t('navbar.account'),
          renderCondition: true,
          to: '/account',
          matchingRoutes: ['/account'],
          showAsTabInMobile: false,
        },
        {
          icon: <FontIcon name="settings_remote" />,
          label: t('navbar.integrations'),
          renderCondition: Boolean(integrationsList.length > 0),
          to: `/integrations`,
          matchingRoutes: [`/integrations`],
          showAsTabInMobile: false,
        },
        {
          icon: <FontIcon name="home" />,
          label: t('navbar.houseSettings'),
          renderCondition: hasSelectedHouse,
          to: `/houses/${selectedHouse?.details.houseId}/settings`,
          matchingRoutes: [
            `/houses/${selectedHouse?.details.houseId}/settings`,
          ],
          showAsTabInMobile: false,
        },
        {
          icon: <FontIcon name="contract_edit" />,
          label: t('navbar.addNewContract'),
          to: '/create-house-contract',
          matchingRoutes: ['/create-house-contract'],
          renderCondition: tenantId === 'niba' && hasSelectedHouse,
          showAsTabInMobile: false,
        },
        {
          icon: <Icon name="SmartPlug" />,
          label: t('navbar.smartDevices'),
          to: `/houses/${selectedHouse?.details.houseId}/smart-devices`,
          matchingRoutes: [
            `/houses/${selectedHouse?.details.houseId}/smart-devices/*`,
          ],
          renderCondition:
            Boolean(shellyIntegration?.isConnected) && hasSelectedHouse,
          showAsTabInMobile: false,
        },
        {
          icon: <FontIcon name="notifications" />,
          label: t('navbar.alerts'),
          renderCondition: hasSelectedHouse,
          to: `/houses/${selectedHouse?.details.houseId}/alerts`,
          matchingRoutes: [`/houses/${selectedHouse?.details.houseId}/alerts`],
          showAsTabInMobile: false,
        },
        {
          icon: <FontIcon name="diversity_3" />,
          label: t('navbar.sharedAccess'),
          renderCondition: hasSelectedHouse,
          to: `/houses/${selectedHouse?.details.houseId}/shared-access`,
          matchingRoutes: [
            `/houses/${selectedHouse?.details.houseId}/shared-access/*`,
          ],
          showAsTabInMobile: false,
        },
        {
          icon: <FontIcon name="connected_tv" />,
          label: t('navbar.kioskMode'),
          renderCondition: screenfull.isEnabled && hasSelectedHouse,
          to: `/houses/${selectedHouse?.details.houseId}/kiosk-mode`,
          matchingRoutes: [
            `/houses/${selectedHouse?.details.houseId}/kiosk-mode`,
          ],
          showAsTabInMobile: false,
        },
      ],
    },
    {
      icon: <FontIcon name="notification_settings" />,
      label: t('navbar.notifications'),
      renderCondition: true,
      to: '/notifications',
      matchingRoutes: ['/notifications'],
      showAsTabInMobile: false,
    },
    {
      icon: <FontIcon name="contact_support" />,
      label: t('navbar.support'),
      renderCondition: true,
      to: '/support',
      matchingRoutes: ['/support/*'],
      showAsTabInMobile: false,
    },
    {
      icon: <FontIcon name="phone" />,
      label: t('navbar.contact'),
      renderCondition: true,
      to: '/contact',
      matchingRoutes: ['/contact'],
      showAsTabInMobile: false,
    },
  ];

  return {
    navigationMenuItems,
    optimizeBadge,
    showInvoicesTabInMobile,
  };
};
