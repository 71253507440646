import { UserHousesListItem } from './UserHousesListItem';
import { UserHouse } from '@clevergy/api-client';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export type UserHousesListProps = {
  houses: UserHouse[];
  dateRangeCurrentDate?: Date;
};

export const UserHousesList: FC<UserHousesListProps> = (props) => {
  const { houses, dateRangeCurrentDate } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 md:table w-full">
      {/* Table header */}
      <div className="hidden md:table-header-group">
        <div className="md:table-cell text-sm font-semibold text-left py-2 md:pl-2">
          {t('houses.userHousesList.location')}
        </div>
        <div className="md:table-cell text-sm font-semibold text-left py-2">
          {t('houses.userHousesList.cups')}
        </div>
        <div className="md:table-cell text-sm font-semibold text-center py-2">
          {t('houses.userHousesList.consumption')}
        </div>
        <div className="md:table-cell text-sm font-semibold text-center py-2 md:pr-2">
          {t('houses.userHousesList.production')}
        </div>
      </div>
      {houses.map((house) => (
        <UserHousesListItem
          key={house.details.houseId}
          house={house}
          dateRangeCurrentDate={dateRangeCurrentDate}
        />
      ))}
    </div>
  );
};
