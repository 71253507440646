import { HousePicker } from './HousePicker';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';

export type PageHeaderProps = {
  title?: string;
  backButton?: boolean;
  onBackButton?: () => void;
  backButtonPath?: string;
  withHousePicker?: boolean;
  hideTitleAndBackButtonForNonMobile?: boolean;
  children?: ReactNode;
};

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  children,
  onBackButton,
  backButtonPath,
  backButton = false,
  withHousePicker,
  hideTitleAndBackButtonForNonMobile = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBackButton = () => {
    if (onBackButton) {
      onBackButton();
      return;
    }

    if (location.key !== 'default') {
      typeof backButtonPath === 'string'
        ? navigate(backButtonPath)
        : navigate(-1);
    } else {
      navigate('/');
    }
  };

  if (!backButton && !title && !withHousePicker && !children) {
    return null;
  }

  return (
    <div
      className={clsx(
        'sticky top-0 z-10 border-b border-clevergy-border sm:relative sm:border-none pt-safe px-safe',
        {
          'bg-white sm:bg-transparent':
            title || backButton || withHousePicker || children,
        },
      )}
    >
      {/* House picker */}
      {withHousePicker && (
        <div className="bg-white px-4 sm:hidden">
          <HousePicker />
        </div>
      )}
      {/* Header title and back button */}
      {(backButton || title) && (
        <div
          className={clsx(
            'px-4 py-2 grid grid-cols-[1fr_auto_1fr] gap-2 sm:bg-transparent',
            {
              'sm:hidden': hideTitleAndBackButtonForNonMobile,
            },
          )}
        >
          {backButton ? (
            <>
              <button
                type="button"
                className="text-left sm:text-clevergy-primary"
                onClick={handleBackButton}
              >
                <div className="sm:hidden">
                  <FontIcon name="arrow_back_ios_new" />
                </div>
                <div className="hidden sm:block">
                  <FontIcon name="chevron_left" inline />
                  Atrás
                </div>
              </button>
            </>
          ) : (
            <span></span>
          )}
          {title && (
            <h2 className="text-xl font-semibold text-center py-2">{title}</h2>
          )}
        </div>
      )}

      {/* Children */}
      {children && (
        <div className="bg-white px-4 py-2 sm:mx-4 sm:rounded-lg sm:mt-4 sm:shadow-moduleContainer lg:mt-0">
          {children}
        </div>
      )}
    </div>
  );
};
