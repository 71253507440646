/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { Equipment } from './Equipment';
import {
  EquipmentFromJSON,
  EquipmentFromJSONTyped,
  EquipmentToJSON,
  EquipmentToJSONTyped,
} from './Equipment';

/**
 *
 * @export
 * @interface EquipmentsList
 */
export interface EquipmentsList {
  /**
   *
   * @type {Array<Equipment>}
   * @memberof EquipmentsList
   */
  equipments?: Array<Equipment>;
}

/**
 * Check if a given object implements the EquipmentsList interface.
 */
export function instanceOfEquipmentsList(
  value: object,
): value is EquipmentsList {
  return true;
}

export function EquipmentsListFromJSON(json: any): EquipmentsList {
  return EquipmentsListFromJSONTyped(json, false);
}

export function EquipmentsListFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EquipmentsList {
  if (json == null) {
    return json;
  }
  return {
    equipments:
      json['equipments'] == null
        ? undefined
        : (json['equipments'] as Array<any>).map(EquipmentFromJSON),
  };
}

export function EquipmentsListToJSON(json: any): EquipmentsList {
  return EquipmentsListToJSONTyped(json, false);
}

export function EquipmentsListToJSONTyped(
  value?: EquipmentsList | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    equipments:
      value['equipments'] == null
        ? undefined
        : (value['equipments'] as Array<any>).map(EquipmentToJSON),
  };
}
