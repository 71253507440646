/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';

/**
 *
 * @export
 * @interface SolarAmortization
 */
export interface SolarAmortization {
  /**
   * Id of the house
   * @type {string}
   * @memberof SolarAmortization
   */
  houseId?: string;
  /**
   *
   * @type {Date}
   * @memberof SolarAmortization
   */
  installationDate?: Date;
  /**
   *
   * @type {number}
   * @memberof SolarAmortization
   */
  initialCost?: number;
  /**
   *
   * @type {number}
   * @memberof SolarAmortization
   */
  baseSavings?: number;
  /**
   *
   * @type {number}
   * @memberof SolarAmortization
   */
  grantReceived?: number;
  /**
   *
   * @type {Date}
   * @memberof SolarAmortization
   */
  createdAt?: Date;
}

/**
 * Check if a given object implements the SolarAmortization interface.
 */
export function instanceOfSolarAmortization(
  value: object,
): value is SolarAmortization {
  return true;
}

export function SolarAmortizationFromJSON(json: any): SolarAmortization {
  return SolarAmortizationFromJSONTyped(json, false);
}

export function SolarAmortizationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SolarAmortization {
  if (json == null) {
    return json;
  }
  return {
    houseId: json['houseId'] == null ? undefined : json['houseId'],
    installationDate:
      json['installationDate'] == null
        ? undefined
        : new Date(json['installationDate']),
    initialCost: json['initialCost'] == null ? undefined : json['initialCost'],
    baseSavings: json['baseSavings'] == null ? undefined : json['baseSavings'],
    grantReceived:
      json['grantReceived'] == null ? undefined : json['grantReceived'],
    createdAt:
      json['createdAt'] == null ? undefined : new Date(json['createdAt']),
  };
}

export function SolarAmortizationToJSON(json: any): SolarAmortization {
  return SolarAmortizationToJSONTyped(json, false);
}

export function SolarAmortizationToJSONTyped(
  value?: SolarAmortization | null,
  ignoreDiscriminator: boolean = false,
): any {
  if (value == null) {
    return value;
  }

  return {
    houseId: value['houseId'],
    installationDate:
      value['installationDate'] == null
        ? undefined
        : value['installationDate'].toISOString(),
    initialCost: value['initialCost'],
    baseSavings: value['baseSavings'],
    grantReceived: value['grantReceived'],
    createdAt:
      value['createdAt'] == null ? undefined : value['createdAt'].toISOString(),
  };
}
